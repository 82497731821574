<div class="step-wrapper">
  <div class="step mb-16">
    <h2 class="mb-8 font-sans font-medium text-3xl te-text-gradient-home inline-block w-full">
      {{ 'pages.move.supplier.subtitle' | translate }}
    </h2>
    <app-alert
      *ngIf="formControl.invalid && formControl.dirty"
      class="inline-block mt-16 w-full"
      [flatDesign]="true"
      [alert]="{
            type: AlertType.error,
            content: {
              messageKey: 'errorMessages.move.requiredValuesMissing',
            },
          }"
    ></app-alert>

    <app-alert
      *ngIf="isFormDisabled"
      class="inline-block mt-16 w-full"
      [flatDesign]="true"
      [alert]="{
          type: AlertType.info,
          content: {
            messageKey: 'pages.move.supplier.savedChoice',
          },
        }"
    ></app-alert>

    <div [class.error]="formControl.invalid && formControl.dirty">
      <p class="form-element input-radio-big mt-4">
        <input type="radio" id="stay" [value]="true" [formControl]="formControl" />
        <label for="stay">
          <div class="flex items-center">
            <div class="pr-6">
              {{ 'pages.move.supplier.moveText' | translate }}
            </div>
            <img ngSrc="./assets/img/macaron-te.svg" width="80" height="80" alt="TotalEnergies logo" />
          </div>
        </label>
      </p>
      <p class="form-element input-radio-big mt-8">
        <input type="radio" id="leave" [value]="false" [formControl]="formControl" />
        <label for="leave"> {{ 'pages.move.supplier.moveAndUnsubscribeText' | translate }}</label>
      </p>
    </div>
  </div>
</div>
<app-navigation [stepValidity]="true" (nextClick)="onNextClicked()" (previousClick)="onPreviousClicked()">
</app-navigation>
