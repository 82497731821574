import { Component, Input, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EnergyType } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { MoveMeter } from '@app/modules/customer-zone/move/models/status.interface';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-move-meter-tag',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './move-meter-tag.component.html',
  styleUrls: ['./move-meter-tag.component.scss'],
})
export class MoveMeterTagComponent implements OnInit {
  @Input() meters: MoveMeter[];
  readonly EnergyType = EnergyType;
  hasMeterNumber: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.hasMeterNumber = this.meters?.some((meter: MoveMeter): boolean => meter.number !== null);
  }
}
