import { Component, DestroyRef, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AbstractControl, ControlContainer, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { UploadDocumentParamsDreCompletionStatusEnumCuzoApi } from '@app/shared/models/cuzo-be-contract';
import { AlertType } from '@app/shared/components/alert/alert.interface';
import { AlertComponent } from '@app/shared/components/alert/alert.component';
import { InputFileUploadComponent } from '@app/shared/components/input-file-upload/input-file-upload.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TooltipPosition, TotalenergiesUiLibraryModule } from '@totalenergiescode/springbox-totalenergies-ui-library';
import { ExpandableRadioBtnComponent } from '../expandable-radio-btn/expandable-radio-btn.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { InputErrorComponent } from '@app/shared/components/input-error/input-error.component';

@Component({
  selector: 'app-dre-document',
  standalone: true,
  imports: [
    CommonModule,
    AlertComponent,
    InputFileUploadComponent,
    ReactiveFormsModule,
    TranslateModule,
    TotalenergiesUiLibraryModule,
    ExpandableRadioBtnComponent,
    InputErrorComponent,
  ],
  templateUrl: './dre-document.component.html',
  styleUrls: ['./dre-document.component.scss'],
})
export class DreDocumentComponent {
  readonly UploadDocumentParamsDreCompletionStatusEnumCuzoApi = UploadDocumentParamsDreCompletionStatusEnumCuzoApi;
  readonly AlertType = AlertType;
  @Output() fileEmitter: EventEmitter<File> = new EventEmitter<File>();
  @Input() uploadInProgress: boolean = false;
  fileName?: string = null;
  validExtensions: string[] = ['pdf', 'jpg', 'jpeg', 'png'];
  readonly maxFileSizeMB: number = 10;
  fileErrorMessages: Record<string, string> = this.translateFileErrorMessages();
  tooltipDreDocs = this.translateTooltipDreDocs();

  constructor(
    private controlContainer: ControlContainer,
    private translate: TranslateService,
    private destroyRef: DestroyRef
  ) {}

  ngOnInit(): void {
    if (this.dreStatusControl.value) {
      this.dreStatusControl.setValue(this.dreStatusControl.value);
      this.onDreStatusChange(this.dreStatusControl.value);
    }
    this.dreStatusControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((dreStatus: UploadDocumentParamsDreCompletionStatusEnumCuzoApi) => {
        this.onDreStatusChange(dreStatus);
      });

    this.tranlateOnLangChange();
  }

  static hasFileToUpload(dreCompletionStatus: UploadDocumentParamsDreCompletionStatusEnumCuzoApi) {
    return [
      UploadDocumentParamsDreCompletionStatusEnumCuzoApi.FULL,
      UploadDocumentParamsDreCompletionStatusEnumCuzoApi.PARTIAL,
    ].includes(dreCompletionStatus);
  }

  get dreFormGroup(): FormGroup {
    return this.controlContainer.control as FormGroup;
  }

  get fileControls(): AbstractControl[] {
    return [this.dreFormGroup.get('fileForFullStatus'), this.dreFormGroup.get('fileForPartialStatus')];
  }

  get dreStatusControl(): AbstractControl {
    return this.dreFormGroup.get('dreStatus');
  }

  onFileSelection(event: Event) {
    const input: HTMLInputElement = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      const selectedFile: File = input.files[0];
      this.fileName = selectedFile.name;
      this.fileEmitter.emit(selectedFile);
    }
  }

  onDreStatusChange(dreStatus: UploadDocumentParamsDreCompletionStatusEnumCuzoApi) {
    const [fileFullControl, filePartialControl] = this.fileControls;

    switch (dreStatus) {
      case UploadDocumentParamsDreCompletionStatusEnumCuzoApi.PARTIAL:
        fileFullControl.disable();
        filePartialControl.enable();
        break;
      case UploadDocumentParamsDreCompletionStatusEnumCuzoApi.FULL:
        filePartialControl.disable();
        fileFullControl.enable();
        break;
      case UploadDocumentParamsDreCompletionStatusEnumCuzoApi.NONE:
        fileFullControl.disable();
        filePartialControl.disable();
        break;
      default:
        break;
    }
  }

  tranlateOnLangChange() {
    this.translate.onLangChange.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.fileErrorMessages = this.translateFileErrorMessages();
      this.tooltipDreDocs = this.translateTooltipDreDocs();
    });
  }

  private translateFileErrorMessages() {
    return {
      required: this.translate.instant('errorMessages.required'),
      maxFileSize: this.translate.instant('errorMessages.maxFileSize', { maxSize: this.maxFileSizeMB }),
    };
  }

  private translateTooltipDreDocs() {
    return {
      content: this.translate.instant('pages.move.dreDocument.tooltip', { maxSize: this.maxFileSizeMB }),
      position: TooltipPosition.Left,
    };
  }
}

_('errorMessages.maxFileSize');
