import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SlideToggleDirective } from '@app/shared/directives/slide-toggle.directive';

@Component({
  selector: 'app-expandable-radio-btn',
  templateUrl: './expandable-radio-btn.component.html',
  styleUrls: ['./expandable-radio-btn.component.scss'],
  imports: [CommonModule, ReactiveFormsModule, SlideToggleDirective],
  standalone: true,
})
export class ExpandableRadioBtnComponent {
  @Input() value: string;
  @Input() name: string;
  @Input() label: string;
  @Input() subtitle?: string;
  @Input() selectedValue: string;
  @Input() option: {
    error: boolean;
  };
  @Output() selectedValueChange: EventEmitter<string> = new EventEmitter<string>();
}
