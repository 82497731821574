import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
@Component({
  selector: 'app-input-error',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './input-error.component.html',
  styleUrls: ['./input-error.component.scss'],
})
export class InputErrorComponent {
  @Input() condition: boolean;
  @Input() errorMessage: string;
  @Input() translatedErrorMessage: string;
  @Input() cssClass: string;
}
