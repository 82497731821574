<div [formGroup]="metersFormGroup">
  <div formGroupName="electricity">
    <div class="flex mt-8 items-center mb-8">
      <img ngSrc="./assets/img/icons/standalone/default/electricity.svg" height="30" width="24" />
      <h3 class="text-lg ml-4">Électricité</h3>
    </div>
    <p>
      Renseignez toutes les informations relatives au compteur électrique présent à votre nouvelle adresse. Il vous est
      possible d’en ajouter plusieurs si nécessaire.
    </p>
    <section class="border-b border-grey/35 pb-12 mb-12">
      <div class="my-8">
        <p class="form-element input-checkbox errors mt-4">
          <input type="checkbox" formControlName="hasMeter" id="hasMeter" />
          <label for="hasMeter">
            Il n’y a pas encore de <strong>compteur électrique</strong> installé à ma nouvelle adresse
          </label>
        </p>
      </div>

      <div class="sm:flex gap-8 mb-8">
        <p
          class="w-full sm:w-1/2 form-element input-text mt-4"
          [class.errors]="
            metersFormGroup.get('electricity.ean').invalid &&
            (metersFormGroup.get('electricity.ean').touched || metersFormGroup.get('electricity.ean').dirty)
          "
        >
          <label>EAN</label>
          <input onlyNumber type="text" formControlName="ean" placeholder="5414XXXXXXXXXXXXXX" />
          <app-input-error
            [cssClass]="'message'"
            [condition]="
              metersFormGroup.get('electricity.ean').errors?.required &&
              (metersFormGroup.get('electricity.ean').touched || metersFormGroup.get('electricity.ean').dirty)
            "
            [errorMessage]="'errorMessages.requiredField'"
          ></app-input-error>
        </p>
        <p class="w-full sm:w-1/2 form-element input-text mt-4">
          <label>Numéro de compteur</label>
          <input type="text" formControlName="meterNumber" placeholder="XXXXXXXX" />
        </p>
      </div>
      <div
        [class.errors]="
          metersFormGroup.get('electricity.meterType').invalid &&
          (metersFormGroup.get('electricity.meterType').touched || metersFormGroup.get('electricity.meterType').dirty)
        "
      >
        <app-custom-select
          [field]="{
            id: 'meterType',
            label: 'Type de compteur',
            formControl: metersFormGroup.get('electricity.meterType'),
            options: meterTypeOptions
          }"
        >
        </app-custom-select>
        <app-input-error
          [cssClass]="'message'"
          [condition]="
            metersFormGroup.get('electricity.meterType').errors?.required &&
            (metersFormGroup.get('electricity.meterType').touched || metersFormGroup.get('electricity.meterType').dirty)
          "
          [errorMessage]="'errorMessages.requiredField'"
        ></app-input-error>
      </div>
      <div class="sm:flex gap-8 mb-8">
        <p class="w-full sm:w-1/2 form-element input-checkbox mt-4">
          <input type="checkbox" formControlName="exclusiveNight" id="exclusiveNight" />
          <label for="exclusiveNight"> Je dispose d'un compteur <strong>exclusif nuit</strong> </label>
        </p>
        <p class="w-full sm:w-1/2 form-element input-checkbox mt-4">
          <input type="checkbox" formControlName="smartMeter" id="smartMeter" />
          <label for="smartMeter"> Je dispose d'un compteur <strong>intelligent</strong> </label>
        </p>
      </div>

      <div
        *ngIf="region === Region.flanders && metersFormGroup.get('electricity.smartMeter').value"
        class="form-element input-text has-suffix max-w-96"
        [class.errors]="
          metersFormGroup.get('electricity.peakPower').invalid &&
          (metersFormGroup.get('electricity.peakPower').touched || metersFormGroup.get('electricity.peakPower').dirty)
        "
      >
        <label for="peakPower"> Puissance de pointe </label>
        <div class="flex">
          <input id="peakPower" type="text" placeholder="0" formControlName="peakPower" />
          <div class="suffix">KVA</div>
        </div>
        <app-input-error
          [cssClass]="'message'"
          [condition]="
            metersFormGroup.get('electricity.peakPower').invalid &&
            (metersFormGroup.get('electricity.peakPower').touched || metersFormGroup.get('electricity.peakPower').dirty)
          "
          [errorMessage]="'errorMessages.requiredField'"
        ></app-input-error>
      </div>
    </section>

    <section class="">
      <app-expandable-checkbox-btn
        [name]="'hasSolarPanels'"
        [value]="metersFormGroup.get('electricity.hasSolarPanels').value"
        [selectedValue]="metersFormGroup.get('electricity.hasSolarPanels').value"
        (selectedValueChange)="onHasSolarPanelChanges($event)"
      >
        <div class="flex items-center" label>Je dispose de panneaux solaires</div>

        <div
          class="content relative -ml-[-26px] -mr-[-26px] pt-0"
          [class.expand]="metersFormGroup.get('electricity.hasSolarPanels').value"
          [class.loading]="!metersFormGroup.get('electricity.hasSolarPanels').value"
        >
          <div class="sm:flex gap-8 mb-8">
            <div
              class="w-full form-element input-text has-suffix"
              [class.errors]="
                submittedForm && metersFormGroup.get('electricity.solarPanelsKva').invalid && solarPanelsKvaInteracted
              "
            >
              <label for="solarPanelsKva"> Solar Panels KVA </label>
              <div class="flex">
                <input
                  id="solarPanelsKva"
                  type="number"
                  formControlName="solarPanelsKva"
                  [placeholder]="solarPanelKvaRange.min"
                  style="padding-top: 14px; padding-bottom: 14px"
                />
                <div class="suffix">KVA</div>
              </div>
              <app-input-error
                [cssClass]="'message'"
                [condition]="submittedForm && solarPanelsKvaControl.errors?.required && solarPanelsKvaInteracted"
                [errorMessage]="'errorMessages.requiredField'"
              ></app-input-error>

              <app-input-error
                [cssClass]="'message'"
                [condition]="
                  submittedForm &&
                  (solarPanelsKvaControl.errors?.min || solarPanelsKvaControl.errors?.max) &&
                  solarPanelsKvaInteracted
                "
                [translatedErrorMessage]="
                  'errorMessages.solarPanelsRange'
                    | translate : { min: solarPanelKvaRange.min, max: solarPanelKvaRange.max }
                "
              ></app-input-error>
            </div>
          </div>
          <div
            *ngIf="region === Region.wallonia && metersFormGroup.get('electricity.smartMeter').value"
            class="sm:flex gap-8 mb-8"
          >
            <div class="w-full sm:w-1/2 form-element input-checkbox mt-4">
              <input id="injectionTariff" type="checkbox" formControlName="injectionTariff" />
              <label for="injectionTariff"> Je dispose du tarif d'injection </label>
            </div>
            <div class="w-full sm:w-1/2">
              <p class="form-element input-radio mt-4">
                <input id="before2024" type="radio" formControlName="solarPanelsInstallationDate" value="BEFORE_2024" />
                <label for="before2024"> PV installé avant 2024 </label>
              </p>
              <p class="form-element input-radio mt-4">
                <input id="after2024" type="radio" formControlName="solarPanelsInstallationDate" value="AFTER_2024" />
                <label for="after2024"> PV installé après 2024 </label>
              </p>
            </div>
          </div>
        </div>
      </app-expandable-checkbox-btn>
    </section>

    <section *ngIf="metersFormGroup.get('electricity.indexes')?.['length']" class="border-t border-grey/35 pt-12 mt-12">
      <div class="md:flex flex-wrap indexes">
        <div
          class="w-full md:w-1/2 overflow-hidden"
          *ngFor="let indexControl of metersFormGroup.get('electricity.indexes')?.['controls']"
        >
          <app-input-meter-index
            [style]="InputMeterStyle.INLINE"
            [type]="indexControl?.get('type')?.value"
            [value]="indexControl?.get('value')?.value"
            [control]="indexControl"
            [newType]="indexControl.get('timeFrame').value"
            [unit]="'general.units.kwh' | translate"
            [label]="
              'components.estimatedIndexDetail.form.meterInput.' +
                indexControl.get('timeFrame').value +
                '.' +
                indexControl.get('type').value +
                '.label' | translate
            "
          >
          </app-input-meter-index>
        </div>
      </div>
    </section>
  </div>
</div>
