<div [formGroup]="metersFormGroup">
  <div formGroupName="gas">
    <div class="flex mt-8 items-center mb-8">
      <img ngSrc="./assets/img/icons/standalone/default/gas.svg" height="30" width="24">
      <h3 class="text-lg ml-4">Gaz</h3>
    </div>
    <p>
      Renseignez toutes les informations relatives au compteur électrique présent à votre nouvelle adresse. Il vous est possible d’en ajouter plusieurs si nécessaire.
    </p>

    <section class="border-b border-grey/35 pb-12 mb-12">
      <div class="my-8">
        <p class="form-element input-checkbox errors mt-4">
          <input type="checkbox" formControlName="hasMeter" id="hasMeter" />
          <label for="hasMeter">
            Il n’y a pas encore de <strong>compteur de gaz</strong> installé à ma nouvelle adresse
          </label>
        </p>
      </div>

      <div class="sm:flex gap-8 mb-8">
        <p class="w-full sm:w-1/2 form-element input-text mt-4">
          <label>EAN</label>
          <input onlyNumber type="text" formControlName="ean" placeholder="5414XXXXXXXXXXXXXX" />
        </p>
        <p class="w-full sm:w-1/2 form-element input-text mt-4">
          <label>Numéro de compteur</label>
          <input type="text" formControlName="meterNumber" placeholder="XXXXXXXX" />
        </p>
      </div>

      <div class="sm:flex gap-8 mb-8">
        <p class="w-full sm:w-1/2 form-element input-checkbox mt-4">
          <input type="checkbox" formControlName="smartMeter" id="smartMeter" />
          <label for="smartMeter">
            Je dispose d'un compteur <strong>intelligent</strong>
          </label>
        </p>
      </div>
    </section>

    <section *ngIf="metersFormGroup?.get('gas.indexes')?.['controls']">
      <div class="flex flex-wrap indexes">
        <div class="w-1/2 overflow-hidden" *ngFor="let indexControl of metersFormGroup.get('gas.indexes')?.['controls']">
          <app-input-meter-index
            [style]="InputMeterStyle.INLINE"
            [type]="indexControl?.get('type')?.value"
            [value]="indexControl?.get('value')?.value"
            [control]="indexControl"
            [newType]="indexControl.get('timeFrame').value"
            [unit]="'general.units.m3' | translate"
            [label]="'components.estimatedIndexDetail.form.meterInput.'+ indexControl.get('timeFrame').value + '.' + indexControl.get('type').value +'.label' | translate"
          >
          </app-input-meter-index>
        </div>
      </div>
    </section>
  </div>
</div>
