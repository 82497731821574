import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { NavigationComponent } from '@app/modules/customer-zone/move/components/move-form/navigation/navigation.component';
import { LoaderStatus } from '@app/modules/customer-zone/move/models/status.interface';
import { catchError, filter, forkJoin, Observable, take, timer } from 'rxjs';
import { MoveFormFacade } from '@app/core/facade/move-form.facade';
import { INITIAL_MOVE_STATE, InvoicingMethod, MoveFormFrontend } from '@app/core/state/move.state';
import { InputFileUploadComponent } from '@app/shared/components/input-file-upload/input-file-upload.component';
import { MainFacade } from '@app/core/facade/main.facade';
import { UploadDocumentParamsDreCompletionStatusEnumCuzoApi } from '@app/shared/models/cuzo-be-contract';
import { AlertType } from '@app/shared/components/alert/alert.interface';
import { AlertComponent } from '@app/shared/components/alert/alert.component';
import { DreDocumentComponent } from '@app/modules/customer-zone/move/components/move-form/parts/dre-document/dre-document.component';
import { ExpandableRadioBtnComponent } from '@app/modules/customer-zone/move/components/move-form/parts/expandable-radio-btn/expandable-radio-btn.component';
import { MoveAddressComponent } from '@app/modules/customer-zone/move/components/move-form/parts/move-address/move-address.component';
import { Address, BillingMethod, MoveInRegistration } from '@app/modules/customer-zone/move/models/movein.interface';
import { MoveFormStep } from '@app/modules/customer-zone/move/components/move-form/steps/MoveFormStep';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { markFormGroupTouched } from '@app/shared/utils/utils.validators';
@Component({
  selector: 'app-new-address',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NavigationComponent,
    ReactiveFormsModule,
    InputFileUploadComponent,
    AlertComponent,
    DreDocumentComponent,
    MoveAddressComponent,
    TranslateModule,
    ExpandableRadioBtnComponent,
  ],
  templateUrl: './new-address.component.html',
  styleUrls: ['./new-address.component.scss'],
})
export class NewAddressComponent extends MoveFormStep<MoveInRegistration> implements OnInit {
  readonly InvoicingMethod = InvoicingMethod;
  readonly AlertType = AlertType;
  form: FormGroup;
  billingEmail: string;
  fileName: string;
  defaultInvoiceDeliveryChannel: InvoicingMethod = InvoicingMethod.POST;
  registration: MoveInRegistration;
  fileUploadInProgress: boolean = false;
  submittedForm: boolean = false;

  constructor(
    protected readonly moveFormFacade: MoveFormFacade,
    private readonly formBuilder: FormBuilder,
    private readonly facade: MainFacade,
    private translate: TranslateService
  ) {
    super(moveFormFacade);

    this.form = this.formBuilder.group({
      newAddress: this.formBuilder.group({
        address: [null, [Validators.required]],
        number: [null, [Validators.required]],
        box: [null, []],
        zipCode: [null, [Validators.required, Validators.min(1000), Validators.max(9999)]],
        locality: [null, [Validators.required]],
        country: [{ value: this.translate.instant('common.country.belgium'), disabled: true }, [Validators.required]],
      }),
      invoicingData: this.formBuilder.group({
        invoicingMethod: [null, [Validators.required]],
      }),
      dre: this.formBuilder.group({
        dreStatus: new FormControl<UploadDocumentParamsDreCompletionStatusEnumCuzoApi>(null, [Validators.required]),
        fileForPartialStatus: [null, [Validators.required]],
        fileForFullStatus: [null, [Validators.required]],
      }),
    });
  }

  ngOnInit(): void {
    this.setFormValues();
  }

  get invoicingMethodControl(): AbstractControl {
    return this.form.get('invoicingData.invoicingMethod');
  }

  setFormValues() {
    forkJoin([
      this.moveFormFacade.state$.pipe(
        filter((): boolean => this.moveFormFacade.state$.value !== INITIAL_MOVE_STATE),
        take(1)
      ),
      this.facade.loadBillingDetails(null, false).pipe(take(1)),
    ]).subscribe(([state, billingDetails]) => {
      this.registration = state?.registration;
      const formValues = state?.form?.newAddress;
      if (formValues?.newAddress) {
        const values = formValues?.newAddress;
        this.form.get('newAddress').patchValue({
          address: values?.address,
          number: values?.number,
          box: values?.box,
          zipCode: values?.zipCode,
          locality: values?.locality,
        });
      }
      this.billingEmail = billingDetails.email;

      const typeOfFacturation = formValues?.invoicingData.invoicingMethod
        ? formValues?.invoicingData.invoicingMethod
        : (billingDetails.invoiceDeliveryChannel as InvoicingMethod);
      this.invoicingMethodControl.setValue(typeOfFacturation);

      if (billingDetails.invoiceDeliveryChannel === InvoicingMethod.EMAIL) {
        this.form.get('invoicingData.invoicingMethod').setValue(InvoicingMethod.EMAIL);
        this.form.get('invoicingData.invoicingMethod').disable();
        this.defaultInvoiceDeliveryChannel = InvoicingMethod.EMAIL;
      } else if (formValues?.invoicingData) {
        const values = formValues?.invoicingData;
        this.form.get('invoicingData').patchValue({
          invoicingMethod: values?.invoicingMethod,
        });
      }
      if (formValues?.dre) {
        const values = formValues?.dre;
        this.fileName = values?.file;
        this.form.get('dre').patchValue({
          dreStatus: values?.dreStatus,
          fileForPartialStatus:
            values?.dreStatus === UploadDocumentParamsDreCompletionStatusEnumCuzoApi.PARTIAL
              ? this.moveFormFacade.createEmptyFile(values?.fileName)
              : null,
          fileForFullStatus:
            values?.dreStatus === UploadDocumentParamsDreCompletionStatusEnumCuzoApi.FULL
              ? this.moveFormFacade.createEmptyFile(values?.fileName)
              : null,
        });
      }
    });
  }

  onNextClickedDefault(): void {
    this.submittedForm = true;
    if (this.form.valid) {
      this.moveFormFacade.loader$.next(LoaderStatus.LOADING);
      this.registration = this.mapFormToMoveIn();
      this.saveFormData().subscribe(() => {
        this.moveFormFacade.loader$.next(LoaderStatus.LOADED);
        this.moveFormFacade.next();
      });
    } else {
      markFormGroupTouched(this.form);
    }
  }

  onPreviousClickedDefault(): void {
    this.moveFormFacade.loader$.next(LoaderStatus.LOADING);
    this.moveFormFacade.updateData({ form: this.getForm() });
    timer(500)
      .pipe(take(1))
      .subscribe((): void => {
        this.moveFormFacade.previous();
        this.moveFormFacade.loader$.next(LoaderStatus.LOADED);
      });
  }

  onFileSelection(file: File): void {
    if (this.form.get('dre.fileForPartialStatus').valid || this.form.get('dre.fileForFullStatus').valid) {
      this.fileUploadInProgress = true;
      this.moveFormFacade
        .uploadMoveInDREFile(
          this.facade.state$.value.reference,
          this.registration.id,
          file,
          this.form.get('dre.dreStatus').value as UploadDocumentParamsDreCompletionStatusEnumCuzoApi
        )
        .pipe(
          take(1),
          catchError((error) => {
            this.fileUploadInProgress = false;
            return error;
          })
        )
        .subscribe(() => (this.fileUploadInProgress = false));
    }
  }

  saveFormData(): Observable<MoveInRegistration> {
    this.registration = this.mapFormToMoveIn();
    return this.moveFormFacade.updateRegistration(this.registration).pipe(take(1));
  }

  onInvoicingMethodChange(value: InvoicingMethod): void {
    this.invoicingMethodControl.setValue(value);
  }

  private mapFormToMoveIn(): MoveInRegistration {
    const payload = structuredClone(this.registration);
    payload.sites[0].address = {
      ...payload.sites[0].address,
      ...this.getFormAddress(this.form.get('newAddress') as FormGroup),
    };
    payload.sites[0].deliveryPoints[0].dreDocumentStatus = this.form.get('dre.dreStatus').value;
    payload.billingInfo = {
      ...payload?.billingInfo,
      billingMethodType: BillingMethod.MONTHLY,
      electronicInvoicing: this.form.get('invoicingData.invoicingMethod').value === this.InvoicingMethod.EMAIL,
      invoiceSendingType: this.form.get('invoicingData.invoicingMethod').value,
      address: {
        ...payload?.billingInfo?.address,
        ...this.getFormAddress(this.form.get('newAddress') as FormGroup),
      },
    };
    return payload;
  }

  private getFormAddress(formGroup: FormGroup): Address {
    return {
      street: formGroup.get('address').value,
      streetNumber: formGroup.get('number').value,
      zip: formGroup.get('zipCode').value,
      box: formGroup.get('box').value,
      city: formGroup.get('locality').value,
      country: formGroup.get('country').value,
    };
  }

  private getForm(): MoveFormFrontend {
    let file = null;
    if (this.form.get('dre.dreStatus').value === UploadDocumentParamsDreCompletionStatusEnumCuzoApi.PARTIAL) {
      file = this.form.get('dre.fileForPartialStatus')?.value;
    } else if (this.form.get('dre.dreStatus').value === UploadDocumentParamsDreCompletionStatusEnumCuzoApi.FULL) {
      file = this.form.get('dre.fileForFullStatus')?.value;
    }

    return {
      ...this.moveFormFacade?.state$?.value?.form,
      newAddress: {
        ...this.form.value,
        dre: {
          dreStatus: this.form.get('dre.dreStatus').value,
          fileName: file instanceof File ? file?.name : null,
        },
      },
    };
  }
}
