import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlContainer, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { InputErrorComponent } from '@app/shared/components/input-error/input-error.component';
@Component({
  selector: 'app-move-address',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, SharedModule, TranslateModule, InputErrorComponent],
  templateUrl: './move-address.component.html',
  styleUrls: ['./move-address.component.scss'],
})
export class MoveAddressComponent implements OnInit {
  constructor(private controlContainer: ControlContainer) {}

  get addressFormGroup(): FormGroup {
    return this.controlContainer.control as FormGroup;
  }

  ngOnInit(): void {}
}
