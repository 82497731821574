import { trigger, transition, style, animate } from '@angular/animations';

export const ShowHide = trigger('ShowHide', [
  transition(':enter', [
    style({ display: 'block', opacity: 0, maxHeight: '0' }),
    animate('0.75s ease-in-out', style({ opacity: 1, maxHeight: '80rem' })),
  ]),
  transition(':leave', [
    style({ opacity: 1, maxHeight: '80rem', display: 'block' }),
    animate('0.75s ease-in-out', style({ opacity: 0, maxHeight: '0', display: 'none' })),
  ]),
]);
