import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SlideToggleDirective } from '@app/shared/directives/slide-toggle.directive';

@Component({
  selector: 'app-expandable-checkbox-btn',
  templateUrl: './expandable-checkbox-btn.component.html',
  styleUrls: ['./expandable-checkbox-btn.component.scss'],
  imports: [CommonModule, ReactiveFormsModule, SlideToggleDirective],
  standalone: true,
})
export class ExpandableCheckboxBtnComponent {
  @Input() value: string;
  @Input() name: string;
  @Input() label: string;
  @Input() selectedValue: string;
  @Output() selectedValueChange: EventEmitter<Event> = new EventEmitter<Event>();
}
