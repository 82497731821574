<section class="mb-12">
  <div class="head flex items-end mb-4">
    <h3 class="text-xl">{{ 'pages.move.finalize.summaryMovingInfo.title' | translate }}</h3>
    <span class="text-primary font-bold cursor-pointer ml-auto text-sm" (click)="edit()">{{
      'pages.move.finalize.editButton' | translate
    }}</span>
  </div>
  <div class="sm:flex bg-grey/10 rounded-4xl px-8 py-8 sm:px-12">
    <div class="flex-1 mb-4 sm:mb-0">
      <h4 class="text-sm font-medium">{{ 'pages.move.finalize.handoverDocument' | translate }}</h4>
      <span class="text-sm">
        <ng-container
          *ngIf="moveDTO?.sites?.[0]?.deliveryPoints?.[0]?.dreDocumentStatus === UploadDocumentParamsDreCompletionStatusEnumCuzoApi.NONE; else hasHandover"
        >
          {{ 'pages.move.finalize.no' | translate }}
        </ng-container>
        <ng-template #hasHandover>
          {{ 'pages.move.finalize.yes' | translate }}
          <span *ngIf="moveDTO?.documents?.length > 0">
            {{ 'pages.move.finalize.handoverStatus.documentLoaded' | translate }}
          </span>
        </ng-template>
      </span>
    </div>
    <div class="flex-1">
      <h4 class="text-sm font-medium">{{ 'pages.move.finalize.summaryMovingInfo.desiredMoveDate' | translate }}</h4>
      <span class="text-sm">
        {{ moveDTO?.ilcDate | date : 'dd/MM/yyyy' : undefined : facade.translate.currentLang + '-BE' }}
      </span>
    </div>
  </div>
</section>
