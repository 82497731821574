import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { NavigationComponent } from '@app/modules/customer-zone/move/components/move-form/navigation/navigation.component';
import { LoaderStatus } from '@app/modules/customer-zone/move/models/status.interface';
import { filter, Observable, take, timer } from 'rxjs';
import { MoveFormFacade } from '@app/core/facade/move-form.facade';
import { AlertComponent } from '@app/shared/components/alert/alert.component';
import { EanControlComponent } from '@app/modules/customer-zone/move/components/move-form/steps/my-meters/ean-control/ean-control.component';
import {
  DeliveryPoint as MoveOutDeliveryPoint,
  INITIAL_MOVE_STATE,
  MoveFormFrontend,
  MoveState,
} from '@app/core/state/move.state';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { EnergyType, RegisterType } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { DatePickerFieldModule } from '@app/shared/date-picker-field/date-picker-field.module';
import { EnergyTypeComponent } from '@app/modules/customer-zone/move/components/move-form/steps/new-meters/energy-type/energy-type.component';
import { ElectricityMeterComponent } from '@app/modules/customer-zone/move/components/move-form/steps/new-meters/electricity-meter/electricity-meter.component';
import {
  MoveInRegistration,
  DeliveryPoint as MoveInDeliveryPoint,
} from '@app/modules/customer-zone/move/models/movein.interface';
import moment from 'moment/moment';
import { MoveFormStep } from '@app/modules/customer-zone/move/components/move-form/steps/MoveFormStep';
import { GasMeterComponent } from '@app/modules/customer-zone/move/components/move-form/steps/new-meters/gas-meter/gas-meter.component';
import { markFormGroupTouched } from '@app/shared/utils/utils.validators';
import { AlertType } from '@app/shared/components/alert/alert.interface';
@Component({
  selector: 'app-new-meters',
  standalone: true,
  imports: [
    CommonModule,
    NavigationComponent,
    AlertComponent,
    EanControlComponent,
    NgOptimizedImage,
    DatePickerFieldModule,
    FormsModule,
    ReactiveFormsModule,
    EnergyTypeComponent,
    ElectricityMeterComponent,
    GasMeterComponent,
  ],
  templateUrl: './new-meters.component.html',
  styleUrls: ['./new-meters.component.scss'],
})
export class NewMetersComponent extends MoveFormStep<MoveInRegistration> implements OnInit {
  form: FormGroup;
  totalSteps: number = 2;
  step: number;
  moveOutDeliveryPoints: MoveOutDeliveryPoint[];
  meters: { hasElectricity: boolean; hasGas: boolean };
  isCurrentStepValid: boolean = false;
  registration: MoveInRegistration;
  submittedForm: boolean = false;
  readonly AlertType = AlertType;

  constructor(
    protected readonly moveFormFacade: MoveFormFacade,
    private readonly formBuilder: FormBuilder,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
    super(moveFormFacade);

    this.form = this.formBuilder.group({
      energyType: this.formBuilder.group({
        date: this.formBuilder.control('', [Validators.required]),
      }),
      meters: this.formBuilder.group({}),
    });
  }

  ngOnInit(): void {
    this.moveFormFacade.state$
      .pipe(
        filter((): boolean => this.moveFormFacade.state$.value !== INITIAL_MOVE_STATE),
        take(1)
      )
      .subscribe((state: MoveState): void => {
        this.registration = state?.registration;
        const newMetersForm = state?.form?.newMeters;
        this.form.get('energyType.date').setValue(newMetersForm?.energyType?.date);
        this.moveOutDeliveryPoints = state.moveDTO?.sites?.[0]?.deliveryPoints;
        this.meters = {
          hasElectricity: this.hasMeter(this.moveOutDeliveryPoints, EnergyType.ELECTRICITY),
          hasGas: this.hasMeter(this.moveOutDeliveryPoints, EnergyType.GAS),
        };
        this.totalSteps = this.meters?.hasElectricity && this.meters.hasGas ? 3 : 2;
        this.step = this.moveFormFacade.state$?.value?.form?.newMeters?.step || 1;
        this.registration = this.syncDeliveryPoints(this.registration);
      });
  }

  onNextClickedDefault(): void {
    if (this.isCurrentStepValid) {
      this.moveFormFacade.loader$.next(LoaderStatus.LOADING);
      const isLastStep = this.step === this.totalSteps;
      this.saveFormData(isLastStep ? 0 : 1).subscribe(() => {
        this.moveFormFacade.loader$.next(LoaderStatus.LOADED);
        if (isLastStep) {
          this.moveFormFacade.next();
        } else {
          this.step++;
        }
      });
    } else {
      this.submittedForm = true;
      markFormGroupTouched(this.form);
    }
  }
  onPreviousClickedDefault(): void {
    this.moveFormFacade.loader$.next(LoaderStatus.LOADING);
    timer(500)
      .pipe(take(1))
      .subscribe((): void => {
        this.moveFormFacade.loader$.next(LoaderStatus.LOADED);
        if (this.step === 1) {
          this.moveFormFacade.updateData({ form: this.getForm(1, 1) });
          this.moveFormFacade.previous();
        } else {
          this.setFormToLocalStorage(this.step, this.step - 1);
          this.step--;
        }
      });
  }

  setFormValidity(isValid: boolean) {
    this.isCurrentStepValid = isValid;
    this.changeDetectorRef.detectChanges();
  }

  /*
   * stepIncreaseAmount is useful since saveFormData can be called from the "Save for later" button.
   * In that case, we don't want to increment the step number.
   * So we are only passing value 1 when we call saveFormData from the onNextClicked
   */
  saveFormData(stepIncreaseAmount: number = 0): Observable<MoveInRegistration> {
    this.setFormToLocalStorage(this.step, this.step + stepIncreaseAmount);
    const registration = this.getUpdatedRegistration();
    return this.moveFormFacade.updateRegistration(registration);
  }

  private syncDeliveryPoints(registration: MoveInRegistration): MoveInRegistration {
    const deliveryPoints = registration?.sites?.[0]?.deliveryPoints;
    if (!deliveryPoints || deliveryPoints.length === 0) {
      return registration;
    }

    const updateDeliveryPoints = (energyType: EnergyType, hasMeter: boolean) => {
      if (hasMeter) {
        if (!this.hasMeter(deliveryPoints, energyType)) {
          deliveryPoints.push({ energyType });
        }
      } else {
        registration.sites[0].deliveryPoints = deliveryPoints.filter((dp) => dp.energyType !== energyType);
      }
    };

    updateDeliveryPoints(EnergyType.ELECTRICITY, this.meters.hasElectricity);
    updateDeliveryPoints(EnergyType.GAS, this.meters.hasGas);

    return registration;
  }

  private setFormToLocalStorage(currentStep: number, nextStep: number) {
    this.moveFormFacade.updateData({ form: this.getForm(currentStep, nextStep) });
    this.moveFormFacade.saveStateToLocalStorage();
  }

  private hasMeter(deliveryPoints: MoveOutDeliveryPoint[] | MoveInDeliveryPoint[], type: EnergyType): boolean {
    return deliveryPoints.some(({ energyType }) => energyType === type);
  }

  private getForm(currentStep: number, nextStep: number): MoveFormFrontend {
    const { electricity, gas } = this.form?.value?.meters;
    const elecIndexes =
      electricity?.indexes?.map((index) => ({ ...index, value: `${index?.unit ?? 0}.${index?.decimal ?? 0}` })) || null;
    const gasIndexes =
      gas?.indexes?.map((index) => ({ ...index, value: `${index?.unit ?? 0}.${index?.decimal ?? 0}` })) || null;

    const meters = {
      ...(this.meters.hasElectricity && {
        electricity: {
          ...electricity,
          indexes: elecIndexes,
        },
      }),
      ...(this.meters.hasGas && {
        gas: {
          ...gas,
          indexes: gasIndexes,
        },
      }),
    };

    return {
      ...this.moveFormFacade.state$?.value?.form,
      newMeters: {
        ...this.moveFormFacade.state$?.value?.form?.newMeters,
        ...(currentStep === 1 ? { energyType: this.form.value.energyType } : { meters }),
        step: nextStep,
      },
    };
  }

  private getUpdatedRegistration() {
    const registration = this.registration;
    if (this.step === 1) {
      return this.setMoveInDateData(registration);
    } else if (this.step === 2 && this.meters.hasElectricity) {
      return this.setElectricityData(registration);
    }
    return this.setGasData(registration);
  }

  private setMoveInDateData(registration: MoveInRegistration): MoveInRegistration {
    registration.sites[0].deliveryPoints[0].requestedServiceDate = moment(
      this.form.get('energyType.date').value
    ).format('YYYY-MM-DD');
    registration.sites[0].deliveryPoints[0].customerWillingServiceDate = moment(
      this.form.get('energyType.date').value
    ).format('YYYY-MM-DD');

    return registration;
  }

  private setElectricityData(registration: MoveInRegistration): MoveInRegistration {
    const electricityForm = this.form.get('meters.electricity');
    const site = this.registration?.sites?.[0];
    site.hasSolarPanels = electricityForm.get('hasSolarPanels').value;
    site.solarPanelKVA = electricityForm.get('solarPanelsKva').value;
    site.peakSolarPower = electricityForm.get('peakPower').value;
    site.hasInjectionTariff = electricityForm.get('injectionTariff').value;
    site.solarPanelsInstallationDateStarting2024 = electricityForm.get('solarPanelsInstallationDate').value;
    const deliveryPointIndex = site?.deliveryPoints?.findIndex(
      ({ energyType }) => energyType === EnergyType.ELECTRICITY
    );
    const deliveryPoint = site?.deliveryPoints?.[deliveryPointIndex];
    deliveryPoint.code = this.form.get('meters.electricity.ean').value;
    deliveryPoint.meters = [
      {
        ...deliveryPoint?.meters?.[0],
        meterNumber: electricityForm.get('meterNumber').value,
        type: electricityForm.get('meterType').value,
        smartMeter: electricityForm.get('smartMeter').value,
        registers: electricityForm.get('indexes').value.map((index) => ({
          timeFrame: index?.timeFrame,
          type: index?.type,
          value: `${index?.unit ?? 0}.${index?.decimal ?? 0}`,
        })),
      },
    ];
    return registration;
  }

  private setGasData(registration: MoveInRegistration): MoveInRegistration {
    const gasForm = this.form.get('meters.gas');
    const site = this.registration?.sites?.[0];
    const deliveryPointIndex = site?.deliveryPoints?.findIndex(({ energyType }) => energyType === EnergyType.GAS);
    const deliveryPoint = site?.deliveryPoints?.[deliveryPointIndex];
    deliveryPoint.code = this.form.get('meters.gas.ean').value;
    deliveryPoint.meters = [
      {
        number: gasForm.get('meterNumber').value,
        type: RegisterType.MONO,
        smartMeter: gasForm.get('smartMeter').value,
        registers: gasForm.get('indexes').value.map((index) => ({
          timeFrame: index?.timeFrame,
          type: index?.type,
          value: `${index?.unit ?? 0}.${index?.decimal ?? 0}`,
        })),
      },
    ];

    return registration;
  }
}
