import { NgModule } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { SharedModule } from '../../../shared/shared.module';
import { AnalyticsModule } from '../../../shared/modules/analytics/analytics.module';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import { MoveStatusComponent } from '@app/modules/customer-zone/move/components/move-status/move-status.component';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    SharedModule,
    AnalyticsModule,
    NgOptimizedImage,
    MoveStatusComponent,
  ],
})
export class MoveModule {}

_('pages.move.pageTitle');
_('components.move.dashboard.statusProgress.missingInfo');
_('components.move.dashboard.statusProgress.completed');
_('components.move.dashboard.statusProgress.sent');
_('components.move.dashboard.statusProgress.processed');
_('components.move.dashboard.statusProgress.finalised');
_('components.move.dashboard.cta.launchMove');
_('components.move.dashboard.card.alerts.missingInfo');
_('components.move.dashboard.card.labels.elecMeter');
_('components.move.dashboard.card.labels.gasMeter');
_('components.move.dashboard.card.labels.selectedMeters');
_('components.move.dashboard.card.labels.newAddress');
_('components.move.dashboard.cta.moveOtherMeters');
_('components.move.dashboard.card.labels.moving');
_('components.move.form.title');
_('components.move.form.step.supplier');
_('components.move.form.step.moving-info');
_('components.move.form.step.my-meters');
_('components.move.form.step.new-address');
_('components.move.form.step.new-meters');
_('components.move.form.step.new-occupant');
_('components.move.form.step.finalise');
_('components.move.form.button.previous');
_('components.move.form.button.next');
_('components.move.form.button.finalize');
_('components.move.form.button.saveForLater');
_('components.move.form.button.cancelMyMove');
_('components.move.form.banner.leavingUser');
_('components.move.form.error.responseError.title');
_('components.move.form.error.responseError.body');
_('components.move.form.myCurrentAddress');
