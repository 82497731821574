import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MoveFormPaths } from '@app/core/models/paths';
import { MoveInRegistration } from '@app/modules/customer-zone/move/models/movein.interface';
import { UploadDocumentParamsDreCompletionStatusEnumCuzoApi } from '@app/shared/models/cuzo-be-contract';
import { InvoicingMethod } from '@app/core/state/move.state';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-summary-new-address',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './summary-new-address.component.html',
  styleUrls: ['./summary-new-address.component.scss'],
})
export class SummaryNewAddressComponent implements OnInit {
  @Input() registration: MoveInRegistration;
  @Output() editEmitter: EventEmitter<MoveFormPaths> = new EventEmitter<MoveFormPaths>();
  readonly UploadDocumentParamsDreCompletionStatusEnumCuzoApi = UploadDocumentParamsDreCompletionStatusEnumCuzoApi;
  readonly InvoicingMethod = InvoicingMethod;

  constructor() {}

  ngOnInit(): void {}

  edit(): void {
    const step = MoveFormPaths.newAddress;
    this.editEmitter.emit(step);
  }
}
