import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ControlContainer, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { Direction } from '@app/modules/customer-zone/consumption/models/deliveryPoint.interface';
import { RegisterType } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { InputMeterStyle } from '@app/shared/components/input-meter-index/input-meter-index.component';
import { TranslateModule } from '@ngx-translate/core';
import { MoveFormFacade } from '@app/core/facade/move-form.facade';

@Component({
  selector: 'app-gas-meter',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, NgOptimizedImage, SharedModule, TranslateModule],
  templateUrl: './gas-meter.component.html',
  styleUrls: ['./gas-meter.component.scss'],
})
export class GasMeterComponent implements OnInit {
  @Output() formValidityEmitter: EventEmitter<boolean> = new EventEmitter(false);
  protected readonly InputMeterStyle = InputMeterStyle;

  constructor(
    private moveFormFacade: MoveFormFacade,
    private controlContainer: ControlContainer,
    private formBuilder: FormBuilder
  ) {}

  get metersFormGroup(): FormGroup {
    return this.controlContainer.control as FormGroup;
  }

  ngOnInit(): void {
    const gasGroup = this.createGasFormGroup();
    this.metersFormGroup.setControl('gas', gasGroup);
    this.emitFormValidity();
  }

  private createGasFormGroup() {
    const state = this.moveFormFacade.state$.value;
    const gasForm = state?.form?.newMeters?.meters?.gas;
    const index = gasForm?.indexes?.[0];
    const indexValue = index?.value;
    const [unit, decimal] = indexValue ? indexValue?.split('.') : [null, null];
    const value = indexValue
      ? indexValue
      : index?.unit || index?.decimal
      ? `${index?.unit ?? 0}.${index?.decimal ?? 0}`
      : null;
    return this.formBuilder.group({
      hasMeter: [gasForm?.hasMeter],
      ean: [gasForm?.ean, [Validators.required]],
      meterNumber: [gasForm?.meterNumber],
      smartMeter: [gasForm?.smartMeter || false],
      indexes: this.formBuilder.array([
        this.formBuilder.group({
          timeFrame: RegisterType.MONO,
          type: Direction.reading,
          unit: index?.unit ?? unit ?? null,
          decimal: index?.decimal ?? decimal ?? null,
          value: gasForm?.indexes?.[0]?.value ?? value ?? null,
        }),
      ]),
    });
  }

  private emitFormValidity(): void {
    const gasGroup = this.metersFormGroup.get('gas');
    this.formValidityEmitter.emit(gasGroup.valid);
    gasGroup.valueChanges.subscribe(() => this.formValidityEmitter.emit(gasGroup.valid));
  }
}
