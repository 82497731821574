import { RegisterType, RegisterTypeExtra } from '@app/modules/customer-zone/consumption/models/consumption.interface';

export interface Dgo {
  gridOperator: string;
  reference: string;
  name: string;
  gln: string;
}

export interface Meter {
  meterNumber: string;
  dgo: Dgo;
  smartMeter: boolean;
  registers: Register[];
  fluvius: boolean;
}

export interface Register {
  timeFrame: string | RegisterType | RegisterTypeExtra;
  direction: Direction;
}

export enum MeterType {
  MONO = 'MONO',
  BI = 'BI',
  MONO_EXC = 'MONO_EXC',
  BI_EXC = 'BI_EXC',
}

export enum MeterTypeOptions {
  MONO = 'TRAD - MONO',
  BI = 'TRAD - BI',
}

export enum Direction {
  production = 'PRODUCTION', // Production and injection have same meaning - produce energy
  injection = 'INJECTION',
  consumption = 'CONSUMPTION',
  offtake = 'OFFTAKE', // For gas consumption
  reading = 'READING',
  injectionReading = 'INJECTION_READING',
}

export interface DeliveryPointsPerReference {
  [reference: string]: MetersPerDeliveryPoint;
}

export interface MetersPerDeliveryPoint {
  [deliveryPoint: string]: Meter[];
}
