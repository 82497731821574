import { MoveFormFacade } from '@app/core/facade/move-form.facade';
import { Observable, switchMap, take } from 'rxjs';

export abstract class MoveFormStep<T> {
  protected constructor(protected moveFormFacade: MoveFormFacade) {
    this.onSaveDataForLater();
  }

  onSaveDataForLater(): void {
    this.moveFormFacade.saveFormListener
      .pipe(
        take(1),
        switchMap(() => this.saveFormData())
      )
      .subscribe(() => {
        this.moveFormFacade.saveStateToLocalStorage();
        this.moveFormFacade.redirectToMoveDashboard();
      });
  }

  onReadonlyNavigationClicked(direction: 'next' | 'prev'): boolean {
    if (this.moveFormFacade.state$.value.readonly) {
      this.moveFormFacade.readonlyModeNav(direction);
      return true;
    }
    return false;
  }

  onNextClicked(): void {
    if (this.onReadonlyNavigationClicked('next')) return;
    this.onNextClickedDefault();
  }

  onPreviousClicked(): void {
    if (this.onReadonlyNavigationClicked('prev')) return;
    this.onPreviousClickedDefault();
  }

  protected abstract onNextClickedDefault(): void;
  protected abstract onPreviousClickedDefault(): void;
  abstract saveFormData(): Observable<T>;
}
