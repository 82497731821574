<div [class.active]="showPopup">
  <div class="success-box">
    <div class="bg-white box-shadow-small mb-24 rounded-4xl inline-block border border-white p-8 w-full">
      <span class="bg-white close close-btn" #close></span>
      <div class="flex items-center">
        <img src="../../../../assets/img/congrat.svg" />
        <span class="font-medium ml-8 text-lg">
          <ng-content select="[title]"></ng-content>
        </span>
      </div>
      <p class="mt-8">
        <ng-content select="[content]"></ng-content>
      </p>
    </div>
  </div>
  <div #close class="backdrop"></div>
</div>
