<div
  class="banner"
  [class.show]="moveState?.route === ORDERED_QUESTION_LEAVE && moveState?.currentStepUrl === MoveFormPaths.myMeters"
>
  <div class="p-8 xl:max-w-screen-xl xl:w-full xl:mx-auto">
    <div class="flex items-center">
      <svg width="75" height="75" viewBox="0 0 75 75" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M41.3672 15.4102C45.0879 10.2979 51.3721 7.61728 57.6562 8.43759C66.1963 9.56552 72.6562 16.8458 72.6562 25.4298C72.6562 29.8683 70.9424 34.1163 67.8516 37.3097L38.7744 67.4415C38.4521 67.7784 38.0127 67.9688 37.5439 67.9688C37.0752 67.9688 36.6357 67.7784 36.3135 67.4415L7.16309 37.3097C4.07227 34.1163 2.34375 29.8536 2.34375 25.4151C2.34375 16.8311 8.80371 9.56552 17.3291 8.45224C23.6133 7.63193 29.8975 10.2979 33.6182 15.4249L36.3281 19.131V21.0938C36.3281 21.4015 36.4453 21.7091 36.665 21.9288L42.6855 27.9493L29.8096 36.5333C29.5312 36.7091 29.3555 37.002 29.2969 37.3243C29.2383 37.6466 29.3408 37.9835 29.5459 38.2325L38.9209 49.9513C39.3311 50.4493 40.0635 50.5372 40.5615 50.127C41.0596 49.7169 41.1475 48.9845 40.7373 48.4864L32.168 37.7638L45.1611 29.0919C45.4541 28.9015 45.6445 28.5792 45.6738 28.2276C45.7031 27.8761 45.5859 27.5245 45.3369 27.2901L38.6719 20.6104V19.131L41.3672 15.4102ZM57.9639 6.12314C50.8447 5.20029 43.7109 8.21786 39.4775 14.0333L37.5 16.7579L35.5225 14.0333C31.2891 8.21786 24.1553 5.18564 17.0361 6.12314C7.35352 7.3829 0 15.6446 0 25.4151C0 30.4688 1.96289 35.3175 5.47852 38.9356L34.6436 69.0821C35.4053 69.8731 36.46 70.3126 37.5586 70.3126C38.6572 70.3126 39.7119 69.8731 40.4736 69.0675L69.5508 38.9356C73.0371 35.3175 75 30.4688 75 25.4298C75 15.6446 67.6465 7.3829 57.9639 6.12314Z"
          fill="url(#paint0_linear_11183_15387)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_11183_15387"
            x1="1.12782"
            y1="5.95507"
            x2="82.9742"
            y2="18.5681"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#029BEB" />
            <stop offset="1" stop-color="#00AA61" />
          </linearGradient>
        </defs>
      </svg>
      <span class="font-medium text-xl pl-12">
        {{ 'components.move.form.banner.leavingUser' | translate }}
      </span>
    </div>
  </div>
</div>
