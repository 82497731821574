import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-success-popup',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './success-popup.component.html',
  styleUrls: ['./success-popup.component.scss'],
})
export class SuccessPopupComponent {
  @ViewChildren('close') closePanel: QueryList<ElementRef>;
  @Output() closeEmitter: EventEmitter<null> = new EventEmitter<null>();
  @Input() showPopup: boolean;

  @HostListener('document:keydown.escape', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    this.closePopup();
  }

  @HostListener('document:click', ['$event'])
  clickout(event) {
    const closeElements: ElementRef[] = this.closePanel?.toArray();
    if (closeElements.some(({ nativeElement }) => nativeElement.contains(event.target))) {
      this.closePopup();
    }
  }

  private closePopup() {
    this.showPopup = false;
    this.closeEmitter.emit(null);
  }
}
