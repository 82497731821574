<div class="flex-1">
  <nav>
    <ng-container *ngIf="!(moveFormFacade.isMobileScreen$ | async); else showMobile">
      <ol class="flex w-full">
        <ng-container *ngFor="let step of moveFormFacade?.route$ | async; let i = index">
          <li
            class="group flex-1 relative"
            [class.active]="i === moveFormFacade?.state$?.value?.currentStep"
            [class.done]="i < moveFormFacade?.state$?.value?.currentStep"
          >
            <div
              aria-hidden="true"
              class="group-last:hidden w-full bg-grey/30 h-[2px] mt-1 absolute bottom-[14px] left-[50%]"
            ></div>
            <div class="relative flex flex-col items-center">
              <span class="text-grey-dark60/50 group-[.active]:text-grey-dark/90 text-sm font-medium mb-4 text-nowrap">
                {{ 'components.move.form.step.' + step | translate }}
              </span>
              <span class="flex h-12 border-[8px] rounded-full border-grey-light items-center">
                <span
                  class="border-grey-dark60 items-center bg-grey-light border w-10 h-10 flex relative z-10 justify-center rounded-full"
                >
                  <span
                    class="invisible group-[.done]:visible group-[.active]:visible bg-primary w-6 h-6 rounded-full items-center z-10"
                  ></span>
                </span>
              </span>
            </div>
          </li>
        </ng-container>
      </ol>
    </ng-container>
    <ng-template #showMobile>
      <div class="flex flex-col">
        <div class="flex text-xs text-grey-dark60">
          {{ 'components.move.dashboard.step' | translate }} {{ moveFormFacade?.state$?.value?.currentStep + 1 }} /
          {{ moveFormFacade?.state$?.value?.numberOfStep }}
        </div>
        <div class="flex text-grey-dark font-medium text-base mt-2">
          {{ moveFormFacade?.state$?.value?.currentStepUrl }}
        </div>
      </div>
    </ng-template>
  </nav>
</div>
