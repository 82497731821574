import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DeliveryPoint, Meter, MoveMeter, MoveRegister, MoveRegisterExtra } from '@app/core/state/move.state';
import { EnergyType } from '@app/modules/customer-zone/consumption/models/consumption.interface';

@Injectable({
  providedIn: 'root',
})
export class DeliveryPointService {
  deliveryPointSubject$: BehaviorSubject<Map<string, DeliveryPoint>> = new BehaviorSubject<Map<string, DeliveryPoint>>(
    new Map()
  );

  get deliveryPointMap(): Map<string, DeliveryPoint> {
    return this.deliveryPointSubject$.getValue();
  }

  addDeliveryPoint(code: string, deliveryPoint: DeliveryPoint): void {
    const currentMap: Map<string, DeliveryPoint> = this.deliveryPointSubject$.getValue();
    currentMap.set(code, deliveryPoint);
    this.deliveryPointSubject$.next(currentMap);
  }

  addMeters(meters: MoveMeter[], deliveryPoint: DeliveryPoint): void {
    const currentMap: Map<string, DeliveryPoint> = this.deliveryPointSubject$.getValue();
    deliveryPoint.meters = deliveryPoint.meters.concat(meters);
    currentMap.set(deliveryPoint.code, deliveryPoint);
    this.deliveryPointSubject$.next(currentMap);
  }

  removeDeliveryPoint(code: string): void {
    const currentMap: Map<string, DeliveryPoint> = this.deliveryPointSubject$.getValue();
    if (currentMap.has(code)) {
      currentMap.delete(code);
      this.deliveryPointSubject$.next(currentMap);
    }
  }

  has(value: string): boolean {
    return this.deliveryPointSubject$.getValue().has(value);
  }

  get(ean: string): DeliveryPoint {
    return this.deliveryPointMap.get(ean);
  }

  createDeliveryPoint(meter: Meter): DeliveryPoint {
    return {
      energyType: meter?.energy,
      code: meter?.ean,
      requestedServiceDate: null,
      moving: false,
      currentSupplier: null,
      meters: [],
    };
  }

  enrichDeliveryPointWithExtraInfo(dp: DeliveryPoint, meters: MoveMeter[]): DeliveryPoint {
    return {
      ...dp,
      meters: meters.map(
        (meter: MoveMeter): MoveMeter => ({
          ...meter,
          registers: meter?.registers.map(
            (register: MoveRegister): MoveRegister => ({
              ...register,
              extra: this.createExtraRegisterInfo(register, dp.energyType),
            })
          ),
        })
      ),
    };
  }

  getListOfDeliveryPoints(): DeliveryPoint[] {
    const deliveryPoints: DeliveryPoint[] = Array.from(this.deliveryPointMap.values());
    return deliveryPoints.filter(
      (item: DeliveryPoint, index: number, self: DeliveryPoint[]): boolean =>
        index === self.findIndex((obj: DeliveryPoint): boolean => obj.code === item.code)
    );
  }

  private createExtraRegisterInfo(register: MoveRegister, energyType: EnergyType): MoveRegisterExtra {
    const type: string = register?.type || register?.direction;
    return {
      label: `pages.move.myMeters.meterInput.${register.timeFrame}.${type}`,
      tooltip: '',
      unit: `pages.move.myMeters.unit.${energyType}`,
    };
  }
}
