<div class="reset-checkbox">
  <div
    class="box-shadow-small rounded-4xl bg-grey-light hover:te-border-gradient-home border-2 border-grey-light"
    [ngClass]="{ 'te-border-gradient-home': selectedValue }"
  >
    <div class="form-element input-checkbox-big errors">
      <input
        type="checkbox"
        [name]="name"
        [value]="value"
        [id]="value"
        [checked]="selectedValue"
        (change)="selectedValueChange.emit($event)"
      />
      <label #toggler [for]="value">
        <ng-content select="[label]"></ng-content>
      </label>
    </div>

    <ng-content></ng-content>
  </div>
</div>
