import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { ControlContainer, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Meter } from '@app/core/state/move.state';
import { MetersControlComponent } from '@app/modules/customer-zone/move/components/move-form/steps/my-meters/meters-control/meters-control.component';
import { EnergyType } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { ExpandableCheckboxBtnComponent } from '@app/modules/customer-zone/move/components/move-form/parts/expandable-checkbox-btn/expandable-checkbox-btn.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-ean-control',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MetersControlComponent,
    NgOptimizedImage,
    TranslateModule,
    ExpandableCheckboxBtnComponent,
  ],
  templateUrl: './ean-control.component.html',
  styleUrls: ['./ean-control.component.scss'],
})
export class EanControlComponent implements OnInit {
  @Input() energyType: EnergyType;
  @Input() meter: Meter;
  @Input() loadingState: Map<string, boolean>;
  @Input() hasPreSwitchLightError: boolean;
  @Output() meterSelectionEmitter: EventEmitter<Event> = new EventEmitter<Event>();

  constructor(private controlContainer: ControlContainer) {}

  get eanFormGroup(): FormGroup {
    return this.controlContainer.control as FormGroup;
  }

  ngOnInit(): void {}

  onCheckboxChange(event: Event) {
    this.meterSelectionEmitter.emit(event);
  }
}
