import { Injectable } from '@angular/core';
import { EnergyType, RegisterType } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { Direction, MeterType } from '@app/modules/customer-zone/consumption/models/deliveryPoint.interface';
import { MoveRegister } from '@app/core/state/move.state';

@Injectable({
  providedIn: 'root',
})
export class RegisterService {
  buildMeterReadingRegisters(
    energyType: EnergyType,
    meterType: MeterType,
    hasExclusiveNight: boolean,
    isSmartMeter: boolean
  ): MoveRegister[] {
    const items = [];
    if (energyType === EnergyType.GAS) {
      items.push({ timeFrame: RegisterType.MONO, type: Direction.reading } as MoveRegister);
    }

    if (hasExclusiveNight) {
      meterType = (meterType + '_EXC') as MeterType;
    }

    if (energyType === EnergyType.ELECTRICITY) {
      if (!isSmartMeter) {
        if (meterType === MeterType.MONO_EXC || meterType === MeterType.MONO) {
          items.push({
            timeFrame: RegisterType.MONO,
            type: Direction.reading,
          } as MoveRegister);
        } else if (
          meterType === MeterType.BI_EXC ||
          meterType === MeterType.BI /*|| meterType === MeterType.PEAK_BI*/
        ) {
          items.push({
            timeFrame: RegisterType.HIGH,
            type: Direction.reading,
          } as MoveRegister);
          items.push({
            timeFrame: RegisterType.LOW,
            type: Direction.reading,
          } as MoveRegister);
        }
      } else {
        // for smart meter (MONO AND BI) --> we request HIGH/LOW register + READING_INJECTION
        items.push({
          timeFrame: RegisterType.HIGH,
          type: Direction.reading,
        } as MoveRegister);
        items.push({
          timeFrame: RegisterType.LOW,
          type: Direction.reading,
        } as MoveRegister);

        items.push({
          timeFrame: RegisterType.HIGH,
          type: Direction.injectionReading,
        } as MoveRegister);
        items.push({
          timeFrame: RegisterType.LOW,
          type: Direction.injectionReading,
        } as MoveRegister);
      }

      if (hasExclusiveNight) {
        items.push({
          timeFrame: RegisterType.NIGHT_EXCLUSIVE,
          type: Direction.reading,
        } as MoveRegister);
      }
    }
    return items;
  }
}
