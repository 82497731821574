<div class="step-wrapper">
  <div class="step mb-16">
    <h2 class="mb-8 font-sans font-medium text-3xl te-text-gradient-home inline-block w-full">Ma nouvelle adresse</h2>
    <span>Renseignez toutes les informations relatives à votre nouvelle adresse</span>
    <app-alert *ngIf="submittedForm && form.invalid &&( form.dirty || form.touched)" 
    class="inline-block mt-16 w-full"
    [flatDesign]="true"
    [alert]="{
          type: AlertType.error,
          content: {
            messageKey: 'errorMessages.fieldValidationErrorMsg',
          },
        }"
  ></app-alert>
    <div [formGroup]="form">
      <section>
        <h3 class="text-2xl font-medium text-grey-dark mt-12">Nouvelle adresse de fourniture</h3>
        
        <app-move-address formGroupName="newAddress"> </app-move-address>
      </section>

      <section class="mt-8">
        <h3 class="text-2xl font-medium text-grey-dark mt-12">Votre contrat</h3>
        <h4 class="text-base font-medium mt-4 mb-2">
          Un avenant à votre contrat en cours sera créé pour votre nouvelle adresse.
        </h4>
        <p class="text-sm">
          Lorem ipsum dolor sit amet consectetur. A proin ipsum pulvinar aliquet est mi scelerisque. Ultricies
          consectetur turpis bibendum id eget scelerisque laoreet neque. Eget eros commodo id ac arcu sit tincidunt ante
          integer. Fusce porta feugiat quam sit arcu malesuada sit.
        </p>
        <a
          [href]="'pages.move.newAddress.moreInfo.link' | translate"
          target="_blank"
          class="text-primary font-bold mt-4 text-sm inline-block w-full"
        >
          {{ 'pages.move.newAddress.moreInfo.label' | translate }}</a
        >
      </section>

      <section
        *ngIf="defaultInvoiceDeliveryChannel !== InvoicingMethod.EMAIL; else emailInvoicingInfo"
        [class.disabled]="form.get('invoicingData.invoicingMethod').disabled"
        formGroupName="invoicingData"
      >
        <ng-container *ngIf="billingEmail; else postFacturationInfo">
          <h3 class="text-2xl font-medium text-grey-dark mt-12 mb-8">Méthode de facturation</h3>
          <div class="grid gap-8">
            <app-expandable-radio-btn
              name="invoicingMethod"
              [label]="'pages.move.invoingChoices.email' | translate"
              [value]="InvoicingMethod.EMAIL"
              [selectedValue]="invoicingMethodControl.value"
              (selectedValueChange)="onInvoicingMethodChange($event)"
              [option]="{
                error:
                  invoicingMethodControl.invalid && (invoicingMethodControl.touched || invoicingMethodControl.dirty)
              }"
            >
              <div class="p-10 pt-2">
                {{ 'pages.move.invoingChoices.currentEmaiLabel' | translate }}
                <span class="text-grey-dark60">{{ billingEmail }}</span>
              </div>
            </app-expandable-radio-btn>

            <app-expandable-radio-btn
              name="invoicingMethod"
              [label]="'pages.move.invoingChoices.post' | translate"
              [value]="InvoicingMethod.POST"
              [selectedValue]="invoicingMethodControl.value"
              (selectedValueChange)="onInvoicingMethodChange($event)"
              [option]="{
                error:
                  invoicingMethodControl.invalid && (invoicingMethodControl.touched || invoicingMethodControl.dirty)
              }"
            >
            </app-expandable-radio-btn>
          </div>
        </ng-container>
      </section>

      <ng-template #emailInvoicingInfo>
        <h3 class="text-2xl font-medium text-grey-dark mt-12">
          {{ 'pages.move.emailInvoicingInfo.title' | translate }}
        </h3>
        <h4 class="text-base font-medium mt-4 mb-2">
          {{ 'pages.move.emailInvoicingInfo.subtitle' | translate }}
        </h4>
        <p class="text-sm">
          {{ 'pages.move.emailInvoicingInfo.content' | translate }}
        </p>
      </ng-template>

      <ng-template #postFacturationInfo>
        <h3 class="text-2xl font-medium text-grey-dark mt-12">
          {{ 'pages.move.postInvoicingInfo.title' | translate }}
        </h3>
        <p class="text-sm">
          {{ 'pages.move.postInvoicingInfo.content' | translate }}
        </p>
      </ng-template>

      <app-dre-document
        formGroupName="dre"
        [uploadInProgress]="fileUploadInProgress"
        (fileEmitter)="onFileSelection($event)"
      >
      </app-dre-document>
    </div>
  </div>

  <app-navigation
    [stepValidity]="!fileUploadInProgress"
    (nextClick)="onNextClicked()"
    (previousClick)="onPreviousClicked()"
  >
  </app-navigation>
</div>
