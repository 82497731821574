<div [formGroup]="addressFormGroup">
  <div class="flex flex-wrap md:flex-nowrap gap-4 sm:gap-8 mb-4">
    <div
      class="w-full md:w-3/5 flex-auto form-element input-text mt-4"
      [class.errors]="
        addressFormGroup.get('address').invalid &&
        (addressFormGroup.get('address').touched || addressFormGroup.get('address').dirty)
      "
    >
      <label>Rue</label>
      <input type="text" formControlName="address" placeholder="Nom de la rue" />
      <app-input-error
        [cssClass]="'message'"
        [condition]="
          addressFormGroup.get('address').errors?.required &&
          (addressFormGroup.get('address').touched || addressFormGroup.get('address').dirty)
        "
        [errorMessage]="'errorMessages.requiredField'"
      ></app-input-error>
    </div>
    <p
      class="flex-1 basis-1/5 form-element input-text mt-4"
      [class.errors]="
        addressFormGroup.get('number').invalid &&
        (addressFormGroup.get('number').touched || addressFormGroup.get('number').dirty)
      "
    >
      <label>N°</label>
      <input onlyNumber type="text" formControlName="number" placeholder="123" />
      <app-input-error
        [cssClass]="'message'"
        [condition]="
          addressFormGroup.get('number').errors?.required &&
          (addressFormGroup.get('number').touched || addressFormGroup.get('number').dirty)
        "
        [errorMessage]="'errorMessages.requiredField'"
      ></app-input-error>
    </p>
    <p class="flex-1 basis-1/5 form-element input-text mt-4">
      <label>Box</label>
      <input type="text" formControlName="box" placeholder="A1" />
    </p>
  </div>
  <div class="flex flex-col sm:flex-row gap-4 sm:gap-8 mb-4">
    <p
      class="w-full sm:w-1/3 form-element input-text mt-4"
      [class.errors]="
        addressFormGroup.get('zipCode').invalid &&
        (addressFormGroup.get('zipCode').touched || addressFormGroup.get('zipCode').dirty)
      "
    >
      <label>Code Postal</label>
      <input onlyNumber type="text" formControlName="zipCode" placeholder="4000" />
      <app-input-error
        [cssClass]="'message'"
        [condition]="
          (addressFormGroup.get('zipCode').errors?.min || addressFormGroup.get('zipCode').errors?.max) &&
          (addressFormGroup.get('zipCode').touched || addressFormGroup.get('zipCode').dirty)
        "
        [errorMessage]="'errorMessages.invalidZipCode'"
      ></app-input-error>
      <app-input-error
        [cssClass]="'message'"
        [condition]="
          addressFormGroup.get('zipCode').errors?.required &&
          (addressFormGroup.get('zipCode').touched || addressFormGroup.get('zipCode').dirty)
        "
        [errorMessage]="'errorMessages.requiredField'"
      ></app-input-error>
    </p>
    <p
      class="w-full sm:w-2/3 form-element input-text mt-4"
      [class.errors]="
        addressFormGroup.get('locality').invalid &&
        (addressFormGroup.get('locality').touched || addressFormGroup.get('locality').dirty)
      "
    >
      <label>Localité</label>
      <input type="text" formControlName="locality" placeholder="Liège" />
      <app-input-error
        [cssClass]="'message'"
        [condition]="
          addressFormGroup.get('locality').errors?.required &&
          (addressFormGroup.get('locality').touched || addressFormGroup.get('locality').dirty)
        "
        [errorMessage]="'errorMessages.requiredField'"
      ></app-input-error>
    </p>
  </div>
  <div
    class="flex gap-8"
    [class.errors]="
      addressFormGroup.get('country').invalid &&
      (addressFormGroup.get('country').touched || addressFormGroup.get('country').dirty)
    "
  >
    <p class="w-full form-element input-text mt-4">
      <label>Pays</label>
      <input type="text" formControlName="country" />
      <app-input-error
        [cssClass]="'message'"
        [condition]="
          addressFormGroup.get('country').errors?.required &&
          (addressFormGroup.get('country').touched || addressFormGroup.get('country').dirty)
        "
        [errorMessage]="'errorMessages.requiredField'"
      ></app-input-error>
    </p>
  </div>
</div>
