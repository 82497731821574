<section class="mb-12">
  <div class="head flex items-end mb-4">
    <h3 class="text-xl">{{ 'pages.move.finalize.summaryNewAddress.title' | translate }}</h3>
    <span (click)="edit()" class="text-primary font-bold cursor-pointer ml-auto text-sm">
      {{ 'pages.move.finalize.editButton' | translate }}
    </span>
  </div>
  <div class="sm:flex bg-grey/10 rounded-4xl px-8 py-8 sm:px-12">
    <div class="flex-1 mb-8 sm:mb-0">
      <div>
        <h4 class="text-sm font-medium">{{ 'pages.move.finalize.summaryNewAddress.newSupplyAddress' | translate }}</h4>
        <span class="text-sm">
          {{ registration?.sites?.[0]?.address?.street }},
          {{ registration?.sites?.[0]?.address?.streetNumber }}
          {{ registration?.sites?.[0]?.address?.box }}
          <br />
          {{ registration?.sites?.[0]?.address?.zip }}
          {{ registration?.sites?.[0]?.address?.city }}
          <br />
          {{ registration?.sites?.[0]?.address?.country }}
        </span>
      </div>

      <div>
        <h4 class="mt-6 text-sm font-medium">
          {{ 'pages.move.finalize.summaryNewAddress.billingAddress' | translate }}
        </h4>
        <span class="text-sm">
          <ng-container *ngIf="registration?.billingInfo?.useDeliveryAddress; else otherAddress">
            {{ 'pages.move.finalize.summaryNewAddress.sameAsNewSupplyAddress' | translate }}
          </ng-container>
        </span>
        <ng-template #otherAddress>
          {{ registration?.billingInfo?.address?.street }},
          {{ registration?.billingInfo?.address?.streetNumber }}
          {{ registration?.billingInfo?.address?.box }}
          <br />
          {{ registration?.billingInfo?.address?.zip }}
          {{ registration?.billingInfo?.address?.city }}
          <br />
          {{ registration?.billingInfo?.address?.country }}
        </ng-template>
      </div>
    </div>
    <div class="flex-1">
      <div>
        <h4 class="text-sm font-medium">
          {{ 'pages.move.finalize.summaryNewAddress.receiveInvoicesByEmail' | translate }}
        </h4>
        <span class="text-sm">
          <ng-container *ngIf="registration?.billingInfo?.invoiceSendingType === InvoicingMethod.EMAIL; else post">
            {{ 'pages.move.finalize.summaryNewAddress.yesEmail' | translate }}
          </ng-container>
          <ng-template #post>
            {{ 'pages.move.finalize.no' | translate }}
          </ng-template>
        </span>
      </div>

      <div>
        <h4 class="mt-6 text-sm font-medium">{{ 'pages.move.finalize.handoverDocument' | translate }}</h4>
        <span class="text-sm">
          <ng-container
            *ngIf="registration?.sites?.[0]?.deliveryPoints?.[0]?.dreDocumentStatus === UploadDocumentParamsDreCompletionStatusEnumCuzoApi.NONE; else hasHandover"
          >
            {{ 'pages.move.finalize.no' | translate }}
          </ng-container>
          <ng-template #hasHandover>
            {{ 'pages.move.finalize.yes' | translate }}
            <span
              *ngIf="registration?.sites?.[0]?.deliveryPoints?.[0]?.dreDocumentStatus !== UploadDocumentParamsDreCompletionStatusEnumCuzoApi.NONE"
            >
              {{ 'pages.move.finalize.handoverStatus.documentLoaded' | translate }}
            </span>
          </ng-template>
        </span>
      </div>
    </div>
  </div>
</section>
