import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeliveryPoint, MoveInRegistration, Site } from '@app/modules/customer-zone/move/models/movein.interface';
import { MoveFormPaths } from '@app/core/models/paths';
import { MoveMeter } from '@app/core/state/move.state';
import { EnergyType } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { MainFacade } from '@app/core/facade/main.facade';
import { TranslateModule } from '@ngx-translate/core';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-summary-new-meters',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './summary-new-meters.component.html',
  styleUrls: ['./summary-new-meters.component.scss'],
})
export class SummaryNewMetersComponent implements OnChanges {
  @Input() registration: MoveInRegistration;
  @Output() editEmitter: EventEmitter<MoveFormPaths> = new EventEmitter<MoveFormPaths>();
  site: Site;
  gasDeliveryPoint: DeliveryPoint;
  gasMeter: MoveMeter = null;
  hasGasIndexes: boolean;

  elecDeliveryPoint: DeliveryPoint;
  elecMeter: MoveMeter = null;
  hasElecIndexes: boolean;

  constructor(public facade: MainFacade) {}

  ngOnChanges() {
    this.site = this.registration?.sites?.[0];
    this.elecDeliveryPoint = this.site?.deliveryPoints?.find(({ energyType }) => energyType === EnergyType.ELECTRICITY);
    if (this.elecDeliveryPoint) {
      this.elecMeter = this.elecDeliveryPoint?.meters?.[0];
      this.hasElecIndexes = this.elecMeter.registers.some((register) => register.value !== null);
    }

    this.gasDeliveryPoint = this.site?.deliveryPoints.find(({ energyType }) => energyType === EnergyType.GAS);
    if (this.gasDeliveryPoint) {
      this.gasMeter = this.gasDeliveryPoint?.meters?.[0];
      this.hasGasIndexes = this.gasMeter.registers.some((register) => register.value !== null);
    }
  }

  edit() {
    const step = MoveFormPaths.newMeters;
    this.editEmitter.emit(step);
  }
}

_('pages.move.finalize.summaryNewMeters.MONO_READING');
_('pages.move.finalize.summaryNewMeters.HIGH_READING');
_('pages.move.finalize.summaryNewMeters.LOW_READING');
_('pages.move.finalize.summaryNewMeters.HIGH_INJECTION_READING');
_('pages.move.finalize.summaryNewMeters.LOW_INJECTION_READING');
_('pages.move.finalize.summaryNewMeters.NIGHT_EXCLUSIVE_READING');
