import { Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MoveDataStatusEnumCuzoApi } from '@app/shared/models/cuzo-be-contract';

@Component({
  selector: 'app-move-status-progress',
  standalone: true,
  imports: [CommonModule, NgOptimizedImage, TranslateModule],
  templateUrl: './move-status-progress.component.html',
  styleUrls: ['./move-status-progress.component.scss'],
})
export class MoveStatusProgressComponent implements OnInit {
  @Input() status: MoveDataStatusEnumCuzoApi;
  @ViewChild('none', { static: true }) noneTemplate!: TemplateRef<any>;
  @ViewChild('missing', { static: true }) missingTemplate!: TemplateRef<any>;
  @ViewChild('ongoing', { static: true }) ongoingTemplate!: TemplateRef<any>;
  @ViewChild('completed', { static: true }) completedTemplate!: TemplateRef<any>;
  readonly steps: Step[] = [Step.COMPLETED, Step.SENT, Step.PROCESSED, Step.FINALISED];
  stepsStatus: StepStatus[] = [];

  constructor() {}

  ngOnInit(): void {
    switch (this.status) {
      case MoveDataStatusEnumCuzoApi.INITIATED:
        this.stepsStatus = [StepStatus.MISSING, StepStatus.NONE, StepStatus.NONE, StepStatus.NONE];
        break;
      case MoveDataStatusEnumCuzoApi.ONGOING_AGENT:
        this.stepsStatus = [StepStatus.COMPLETED, StepStatus.COMPLETED, StepStatus.ONGOING, StepStatus.NONE];
        break;
      case MoveDataStatusEnumCuzoApi.COMPLETED:
        this.stepsStatus = [StepStatus.COMPLETED, StepStatus.COMPLETED, StepStatus.COMPLETED, StepStatus.COMPLETED];
        break;
    }
  }

  getTemplate(status: StepStatus): TemplateRef<any> {
    switch (status) {
      case StepStatus.NONE:
        return this.noneTemplate;
      case StepStatus.MISSING:
        return this.missingTemplate;
      case StepStatus.ONGOING:
        return this.ongoingTemplate;
      case StepStatus.COMPLETED:
        return this.completedTemplate;
      default:
        return this.noneTemplate; // Template par défaut
    }
  }
}

enum Step {
  COMPLETED = 'completed',
  SENT = 'sent',
  PROCESSED = 'processed',
  FINALISED = 'finalised',
}

enum StepStatus {
  NONE = 'NONE',
  COMPLETED = 'COMPLETED',
  ONGOING = 'ONGOING',
  MISSING = 'MISSING',
}
