<div class="flex flex-col sm:flex-row border-t border-grey/30 pt-8 w-full">
  <button
    (click)="onPreviousClick()"
    *ngIf="!moveFormFacade.isFirstStep()"
    class="btn btn-secondary font-bold w-full sm:w-auto"
  >
    {{ 'components.move.form.button.previous' | translate }}
  </button>
  <button
    *ngIf="!(moveFormFacade.isLastStep() && moveFormFacade.state$.value.readonly)"
    [disabled]="!stepValidity"
    (click)="onNextClick()"
    class="btn btn-primary font-bold -order-1 w-full mb-4 sm:mb-0 sm:order-2 sm:ml-auto sm:w-auto"
  >
    {{ 'components.move.form.button.' + (moveFormFacade.isLastStep() ? 'finalize' : 'next') | translate }}
  </button>
</div>
