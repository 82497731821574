import { ElementRef, Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { Subscription } from 'rxjs';
import { Region } from '@app/shared/models/region.interface';
import { AppConfig } from './app-config';
import jwt_decode from 'jwt-decode';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakConfig } from 'keycloak-js';
import { CrossSellType } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { EliqAccessRights, Profile } from '@app/shared/resolvers/user-type-resolver/models/user-type.interface';
import { MeterConfig } from '@app/shared/models/meter.interface';
import { ContractDtoCuzoApi, ContractDtoTypeEnumCuzoApi } from '../models/cuzo-be-contract';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  public activeInfoCookieName = `${this.conf?.config?.app?.name}ActiveInformations`;
  public pageTitleSubscription: Subscription;

  constructor(
    private cookieService: CookieService,
    private router: Router,
    private translate: TranslateService,
    private titleService: Title,
    private conf: AppConfig,
    private keycloakService: KeycloakService
  ) {}

  public static formatIBAN(iban: string) {
    if (!iban) {
      return;
    }
    return iban
      .replace(/[^\dA-Z]/g, '')
      .replace(/(.{4})/g, '$1 ')
      .trim();
  }

  public static toCamel(s: string): string {
    return s.replace(/([-_][a-z])/gi, ($1) => $1.toUpperCase().replace('-', '').replace('_', ''));
  }

  public static isMobile(): boolean {
    let check = false;
    ((a) => {
      if (
        // eslint-disable-next-line max-len
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
          a
        ) ||
        // eslint-disable-next-line max-len
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          a.substr(0, 4)
        )
      ) {
        check = true;
      }
    })(navigator.userAgent || navigator.vendor || window['opera']);
    return check;
  }

  public static getRegion = (zipCode: number) => {
    if (zipCode >= 1000 && zipCode <= 1212) {
      return Region.brussels;
    } else if ((zipCode >= 1500 && zipCode <= 3999) || (zipCode >= 8000 && zipCode <= 9999)) {
      return Region.flanders;
    } else {
      return Region.wallonia;
    }
  };

  public static isBrussels = (zipCode: number) => UtilsService.getRegion(zipCode) === Region.brussels;
  public static isFlanders = (zipCode: number) => UtilsService.getRegion(zipCode) === Region.flanders;
  public static isWallonia = (zipCode: number) => UtilsService.getRegion(zipCode) === Region.wallonia;

  public downLoadFile(data: any, type: string, filename: string = null) {
    const blob = new Blob([data], { type });
    if (filename) {
      const nav = window.navigator as any;
      if (nav.msSaveOrOpenBlob) {
        nav.msSaveBlob(blob, filename);
      } else {
        const a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    } else {
      this.openFile(data, type);
    }
  }

  public getSiteProfile(eliqAccessRights: EliqAccessRights, contracts: ContractDtoCuzoApi[]): Profile {
    let ean: ContractDtoCuzoApi = contracts.find(({ type }) => type === ContractDtoTypeEnumCuzoApi.ELECTRICITY);
    if (!ean) {
      ean = contracts.find(({ type }) => type === ContractDtoTypeEnumCuzoApi.GAS);
    }
    return (
      eliqAccessRights?.profileByDeliveryPointReference &&
      eliqAccessRights?.profileByDeliveryPointReference[ean.deliveryPointReference]
    );
  }

  public getMeterConfig(profile: Profile): MeterConfig {
    if (profile?.smart && profile?.fluvius) {
      return profile?.injection === 1 ? MeterConfig.smartAndSolar : MeterConfig.smart;
    } else {
      return profile?.injection === 0 ? MeterConfig.basic : MeterConfig.solar;
    }
  }

  public openFile(data: any, type: string) {
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed === 'undefined') {
      alert('Please disable your Pop-up blocker and try again.');
    }
  }

  public setPageTitle(titlePath: string) {
    const myTotalEnergies = '| TotalEnergies';
    this.titleService.setTitle(`${this.translate.instant(titlePath)} ${myTotalEnergies}`);
    if (this.pageTitleSubscription) {
      this.pageTitleSubscription.unsubscribe();
    }
    this.pageTitleSubscription = this.translate.onLangChange.subscribe(() => {
      this.titleService.setTitle(`${this.translate.instant(titlePath)} ${myTotalEnergies}`);
    });
  }

  public isMissingKeycloakAccountInfo() {
    const keycloakInstance = this.keycloakService.getKeycloakInstance();
    const userInfo: any = jwt_decode(keycloakInstance.token);
    return (
      (!userInfo.given_name && !userInfo.family_name) ||
      (userInfo.given_name.length === 0 && userInfo.family_name.length === 0)
    );
  }

  public redirectToKeycloakAccountPage() {
    const keycloakConfig: KeycloakConfig = this.conf.config.keycloak;
    const baseURL: string = this.getBaseUrl();
    // eslint-disable-next-line max-len
    const accountPageURL = `${keycloakConfig.url}/realms/${keycloakConfig.realm}/account?referrer=${keycloakConfig.clientId}&referrer_uri=${baseURL}&kc_locale=${this.translate.currentLang}&missingFirstnameLastname=true`;
    window.location.href = accountPageURL;
  }

  public redirectToErrorPage(error = null) {
    this.router.navigate([`/error`, error]);
  }

  public redirectToSameRoute() {
    this.router.navigateByUrl(`${this.router.url}`, { skipLocationChange: false });
  }

  public redirectTo(path: string = '') {
    this.router.navigate([`${path}`], { skipLocationChange: true });
  }

  public getCookieValue(cookieName: string, property: string = null) {
    if (!this.cookieService.get(cookieName)) {
      return false;
    }
    try {
      const cookie = JSON.parse(this.cookieService.get(cookieName));
      if (!property) {
        return cookie;
      }
      if (property && cookie[property]) {
        return cookie[property];
      }
      if (property && !cookie[property]) {
        return false;
      }
    } catch (e) {
      return this.cookieService.get(cookieName);
    }
  }

  public setCookieValue(cookieName: string, value, expiredDate: Date = null, domain: string = null) {
    const userAgent = navigator.userAgent.toLowerCase();
    const isWebkitBrowser = userAgent.indexOf('chrome') > -1 || userAgent.indexOf('safari') > -1;
    const sameSite = isWebkitBrowser ? 'Strict' : 'None';
    value = typeof value !== 'string' ? JSON.stringify(value) : value;

    if (expiredDate === null) {
      expiredDate = new Date();
      expiredDate.setDate(expiredDate.getDate() + this.conf.config.cookieExpiredDays);
    }

    this.cookieService.set(cookieName, value, expiredDate, '/', domain, false, sameSite);
  }

  public checkCookie(cookieName: string): boolean {
    return this.cookieService.check(cookieName);
  }

  public deleteCookie(cookieName: string) {
    this.cookieService.delete(cookieName, '/');
  }

  public deleteCookies() {
    this.deleteCookie(this.activeInfoCookieName);
  }

  public getBaseUrl() {
    return this.conf.config.url.base[this.translate.currentLang];
  }

  public getMoveUrl() {
    return this.conf.config.url.move[this.translate.currentLang];
  }

  public getBlogUrl() {
    return this.conf.config.url.blog[this.translate.currentLang];
  }

  public getHelpCenterUrl() {
    return this.conf.config.url.helpCenter[this.translate.currentLang];
  }

  public getContactUsUrl() {
    return this.conf.config.url.contactUs[this.translate.currentLang];
  }

  public getMgmUrl() {
    return this.conf.config.url.mgm[this.translate.currentLang];
  }

  public getConsumptionVideoUrl() {
    return this.conf.config.url.consumptionVideo[this.translate.currentLang];
  }

  public getCrossSellUrl(crossSellType: CrossSellType | string) {
    return this.conf.config.url[crossSellType][this.translate.currentLang];
  }

  public appendQueryParams(fullURL: string, params) {
    const url = new URL(fullURL);
    const urlParams = url.searchParams;
    // @ts-ignore
    Object.entries(params).forEach(([key, value]) => urlParams.set(key, value));
    url.search = urlParams.toString();
    return url.toString();
  }

  public floatEqual(a: number, b: number) {
    if (a === b) {
      return true;
    }

    const diff = Math.abs(a - b);

    if (diff < Number.EPSILON) {
      return true;
    }

    return diff <= Number.EPSILON * Math.min(Math.abs(a), Math.abs(b));
  }

  public convertMaskedStringToNumber(str: string): number {
    return str ? Number(`${str}`.replace(/\s+/g, '').replace(',', '.')) : 0;
  }

  public round(value: number, places = 0): number {
    const multiplier = Math.pow(10, places);

    return Math.round(value * multiplier) / multiplier;
  }

  public elementsOverlap(firstBox: ElementRef, secondBox: ElementRef): boolean {
    if (!firstBox || !secondBox) {
      return false;
    }
    const smallSpace = 5;
    const firstBoxCoordinates = firstBox.nativeElement.getBoundingClientRect();
    const secondBoxCoordinates = secondBox.nativeElement.getBoundingClientRect();
    if (firstBoxCoordinates.x < secondBoxCoordinates.x) {
      return firstBoxCoordinates.x + firstBoxCoordinates.width >= secondBoxCoordinates.x - smallSpace;
    } else {
      return secondBoxCoordinates.x + secondBoxCoordinates.width >= firstBoxCoordinates.x - smallSpace;
    }
  }

  public createEmptyFileWithName(fileName: string): File {
    return new File([], fileName);
  }
}
