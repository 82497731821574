<div class="step-wrapper">
  <div class="step mb-16">
    <h2 class="mb-8 font-sans font-medium text-3xl te-text-gradient-home inline-block w-full">
      Mon déménagement
    </h2>
    <app-alert *ngIf="submittedForm && form.invalid &&( form.touched ||form.dirty)" 
      class="inline-block mt-16 w-full"
      [flatDesign]="true"
      [alert]="{
            type: AlertType.error,
            content: {
              messageKey: 'errorMessages.fieldValidationErrorMsg',
            },
          }"
    ></app-alert>
    <div>
      <app-dre-document
        [formGroup]="form"
        [uploadInProgress]="fileUploadInProgress"
        (fileEmitter)="onFileSelection($event)"
      >
      </app-dre-document>
      

      <div [formGroup]="form">
        <h3 class="text-2xl font-medium text-grey-dark mt-16">Date de déménagement</h3>
        <p class="mt-6 mb-4">Indiquez la date souhaitée de déménagement</p>
        <div class="relative mt-8">
          <div
            [class.hidden]="form.get('dreStatus').value"
            class="absolute top-0 left-0 w-full h-full cursor-not-allowed rounded-2xl bg-black/10 z-10 grayscale"
          ></div>
          <date-picker-field
            formControlName="movingDate"
            [fieldLabel]="'Date de déménagement'"
            [dateRange]="serviceDateRange$ | async"
            (dateChanged)="onDateSelection($event)"
          >
          </date-picker-field>
        
        </div>
        <div *ngIf="form.get('movingDate').invalid &&( form.get('movingDate').touched ||form.get('movingDate').dirty)"  class="error inline-block w-full text-primary text-sm">{{ 'errorMessages.requiredField' | translate }}</div> 
        <app-alert
          *ngIf="hasMissingMandatoryIndexes"
          class="inline-block mt-16 w-full"
          [flatDesign]="true"
          [alert]="{
          type: AlertType.info,
          content: {
            message: 'Vous avez choisis une date de déménagement dans le passé, vous devez donc renseigner vos indexes. Veuillez retourner à l\'étape précédente pour les compléter.',
          },
        }"
        ></app-alert>
      </div>
    </div>
  </div>
</div>
<app-navigation
  [stepValidity]=" !hasMissingMandatoryIndexes && !fileUploadInProgress"
  (nextClick)="onNextClicked()"
  (previousClick)="onPreviousClicked()"
>
</app-navigation>
