import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MoveDTO } from '@app/core/state/move.state';
import { UploadDocumentParamsDreCompletionStatusEnumCuzoApi } from '@app/shared/models/cuzo-be-contract';
import { MainFacade } from '@app/core/facade/main.facade';
import { MoveFormPaths } from '@app/core/models/paths';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-summary-moving-info',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './summary-moving-info.component.html',
  styleUrls: ['./summary-moving-info.component.scss'],
})
export class SummaryMovingInfoComponent implements OnInit {
  @Input() moveDTO: MoveDTO;
  @Output() editEmitter: EventEmitter<MoveFormPaths> = new EventEmitter<MoveFormPaths>();
  readonly UploadDocumentParamsDreCompletionStatusEnumCuzoApi = UploadDocumentParamsDreCompletionStatusEnumCuzoApi;

  constructor(public facade: MainFacade) {}

  ngOnInit(): void {}

  edit() {
    const step = MoveFormPaths.movingInfo;
    this.editEmitter.emit(step);
  }
}
