import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DeliveryPoint, MoveDTO, MoveMeter } from '@app/core/state/move.state';
import { MoveFormPaths } from '@app/core/models/paths';
import { EnergyType } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-summary-my-meters',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './summary-my-meters.component.html',
  styleUrls: ['./summary-my-meters.component.scss'],
})
export class SummaryMyMetersComponent implements OnChanges {
  @Input() moveDTO: MoveDTO;
  @Output() editEmitter: EventEmitter<MoveFormPaths> = new EventEmitter<MoveFormPaths>();
  gasDeliveryPoint: DeliveryPoint;
  gasMeter: MoveMeter = null;
  hasGasIndexes: boolean;

  elecDeliveryPoint: DeliveryPoint;
  elecMeter: MoveMeter = null;
  hasElecIndexes: boolean;

  constructor() {}

  ngOnChanges() {
    const deliveryPoints = this.moveDTO?.sites?.[0]?.deliveryPoints;
    this.elecDeliveryPoint = deliveryPoints?.find(({ energyType }) => energyType === EnergyType.ELECTRICITY);
    if (this.elecDeliveryPoint) {
      this.elecMeter = this.elecDeliveryPoint?.meters?.[0];
      this.hasElecIndexes = this.elecMeter.registers.some((register) => register.value !== null);
    }

    this.gasDeliveryPoint = deliveryPoints.find(({ energyType }) => energyType === EnergyType.GAS);
    if (this.gasDeliveryPoint) {
      this.gasMeter = this.gasDeliveryPoint?.meters?.[0];
      this.hasGasIndexes = this.gasMeter.registers.some((register) => register.value !== null);
    }
  }

  edit() {
    const step = MoveFormPaths.myMeters;
    this.editEmitter.emit(step);
  }
}
