import { MoveFormPaths } from '@app/core/models/paths';

export const ORDERED_QUESTION_STAY: MoveFormPaths[] = [
  MoveFormPaths.supplier,
  MoveFormPaths.myMeters,
  MoveFormPaths.movingInfo,
  MoveFormPaths.newAddress,
  MoveFormPaths.newMeters,
  MoveFormPaths.newOccupant,
  MoveFormPaths.finalise,
];

export const ORDERED_QUESTION_LEAVE: MoveFormPaths[] = [
  MoveFormPaths.supplier,
  MoveFormPaths.myMeters,
  MoveFormPaths.movingInfo,
  MoveFormPaths.newOccupant,
  MoveFormPaths.finalise,
];
