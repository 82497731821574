import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import {
  AbstractControl,
  ControlContainer,
  FormArray,
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { SharedModule } from '@app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { MoveAddressComponent } from '@app/modules/customer-zone/move/components/move-form/parts/move-address/move-address.component';
import { DatePickerFieldModule } from '@app/shared/date-picker-field/date-picker-field.module';
import { UtilsService } from '@app/shared/utils/utils.service';
import { Region } from '@app/shared/models/region.interface';
import { EnergyType } from '@app/modules/customer-zone/consumption/models/consumption.interface';
import { MeterTypeOptions } from '@app/modules/customer-zone/consumption/models/deliveryPoint.interface';
import { InputMeterStyle } from '@app/shared/components/input-meter-index/input-meter-index.component';
import moment from 'moment';
import { MoveFormFacade } from '@app/core/facade/move-form.facade';
import { MoveInRegistration } from '@app/modules/customer-zone/move/models/movein.interface';
import { RegisterService } from '@app/modules/customer-zone/move/services/form/register.service';
import { MoveRegister } from '@app/core/state/move.state';
import { combineLatest, startWith } from 'rxjs';
import { ExpandableCheckboxBtnComponent } from '@app/modules/customer-zone/move/components/move-form/parts/expandable-checkbox-btn/expandable-checkbox-btn.component';
import { InputErrorComponent } from '@app/shared/components/input-error/input-error.component';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';

@Component({
  selector: 'app-electricity-meter',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgOptimizedImage,
    SharedModule,
    TranslateModule,
    MoveAddressComponent,
    DatePickerFieldModule,
    ExpandableCheckboxBtnComponent,
    InputErrorComponent,
  ],
  templateUrl: './electricity-meter.component.html',
  styleUrls: ['./electricity-meter.component.scss'],
})
export class ElectricityMeterComponent implements OnInit {
  @Output() formValidityEmitter: EventEmitter<boolean> = new EventEmitter(false);
  @Input() registration: MoveInRegistration;
  @Input() moveInDate: Date;
  @Input() submittedForm: boolean;
  readonly Region = Region;
  readonly InputMeterStyle = InputMeterStyle;
  readonly meterTypeOptions = MeterTypeOptions;
  region: Region;
  readonly solarPanelKvaRange = { min: 0.1, max: 10 };
  readonly solarPanelKvaValidators = [
    Validators.min(this.solarPanelKvaRange.min),
    Validators.max(this.solarPanelKvaRange.max),
    Validators.required,
  ];

  constructor(
    private moveFormFacade: MoveFormFacade,
    private controlContainer: ControlContainer,
    private formBuilder: FormBuilder,
    private registerService: RegisterService
  ) {}

  get metersFormGroup(): FormGroup {
    return this.controlContainer.control as FormGroup;
  }

  get solarPanelsKvaControl(): AbstractControl {
    return this.metersFormGroup.get('electricity.solarPanelsKva');
  }

  get solarPanelsKvaInteracted(): boolean {
    return this.solarPanelsKvaControl.touched || this.solarPanelsKvaControl.dirty;
  }

  ngOnInit(): void {
    this.region = UtilsService.getRegion(+this.registration?.sites?.[0]?.address?.zip);
    const electricityGroup = this.createElectricityFormGroup();
    this.metersFormGroup.setControl('electricity', electricityGroup);
    this.setFormValidity();
    this.subscribeToFormChanges();
    this.emitFormValidity();
  }

  private createElectricityFormGroup(): FormGroup {
    const state = this.moveFormFacade.state$.value;
    const electricityForm = state?.form?.newMeters?.meters?.electricity;
    return this.formBuilder.group({
      hasMeter: [electricityForm?.hasMeter || false],
      ean: [electricityForm?.ean || null, [Validators.required]],
      meterNumber: [electricityForm?.meterNumber || null],
      meterType: [electricityForm?.meterType || null, [Validators.required]],
      smartMeter: [electricityForm?.smartMeter || false],
      exclusiveNight: [electricityForm?.exclusiveNight || false],
      peakPower: [electricityForm?.peakPower || null, [Validators.min(2.5), Validators.max(30)]],
      hasSolarPanels: [electricityForm?.hasSolarPanels || false],
      solarPanelsKva: [electricityForm?.solarPanelsKva || null, this.solarPanelKvaValidators],
      solarPanelsInstallationDate: [electricityForm?.solarPanelsInstallationDate || null],
      injectionTariff: [electricityForm?.injectionTariff || false],
      indexes: this.formBuilder.array(
        electricityForm?.indexes?.length ? this.buildIndexesArray(electricityForm?.indexes, null) : []
      ),
    });
  }

  private subscribeToFormChanges(): void {
    const electricityFormGroup = this.metersFormGroup.get('electricity') as FormGroup;
    electricityFormGroup.valueChanges.subscribe((): void => this.setFormValidity());
    this.rebuildIndexesFieldsOnValuesChanges(electricityFormGroup);
  }

  private rebuildIndexesFieldsOnValuesChanges(electricityFormGroup: FormGroup): void {
    combineLatest([
      electricityFormGroup.get('meterType').valueChanges.pipe(startWith(electricityFormGroup.get('meterType').value)),
      electricityFormGroup
        .get('exclusiveNight')
        .valueChanges.pipe(startWith(electricityFormGroup.get('exclusiveNight').value)),
      electricityFormGroup.get('smartMeter').valueChanges.pipe(startWith(electricityFormGroup.get('smartMeter').value)),
    ]).subscribe(() => {
      if (!electricityFormGroup.get('meterType').value) {
        return;
      }
      const existingIndexes = electricityFormGroup.get('indexes') as FormArray;
      const indexes = this.registerService.buildMeterReadingRegisters(
        EnergyType.ELECTRICITY,
        electricityFormGroup.get('meterType').value,
        electricityFormGroup.get('exclusiveNight').value,
        electricityFormGroup.get('smartMeter').value
      );
      const indexesFormGroup = this.buildIndexesArray(indexes, existingIndexes);
      this.setFormArrayIndexes(electricityFormGroup, indexesFormGroup);
    });
  }

  private buildIndexesArray(indexes: MoveRegister[], existingIndexes: FormArray<FormGroup>): FormGroup[] {
    return indexes.map((index) => this.createIndexFormGroup(index, existingIndexes));
  }

  private createIndexFormGroup(index: MoveRegister, existingIndexes: FormArray<FormGroup>): FormGroup {
    const existingIndex = existingIndexes?.value?.find(
      (ei: MoveRegister) => ei.timeFrame === index.timeFrame && ei.type === index.type
    );
    const [unit, decimal] = index?.value ? index?.value?.split('.') : [null, null];
    const value = index?.value
      ? index?.value
      : index?.['unit'] || index?.['decimal']
      ? `${index?.['unit'] ?? 0}.${index?.['decimal'] ?? 0}`
      : null;
    return this.formBuilder.group({
      timeFrame: index?.timeFrame,
      type: index?.type,
      unit: [index?.['unit'] ?? unit ?? existingIndex?.unit ?? null, this.getIndexesValidators()],
      decimal: index?.['decimal'] ?? decimal ?? existingIndex?.decimal ?? null,
      value: index?.value ?? value ?? existingIndex?.value ?? null,
    });
  }

  private setFormArrayIndexes(electricityFormGroup: FormGroup, indexes: any[]) {
    const indexesArray: FormArray<FormGroup> = electricityFormGroup.get('indexes') as FormArray;
    indexesArray.clear();
    indexes.forEach((item) => indexesArray.push(item));
  }

  private setFormValidity(): void {
    const electricityFormGroup = this.metersFormGroup.get('electricity') as FormGroup;
    this.updateValidatorsForFlanders(electricityFormGroup);
    this.updateValidatorsBasedOnSolarPanels(electricityFormGroup);
    this.updateValidatorsForWallonia(electricityFormGroup);
  }

  private updateValidatorsForFlanders(electricityFormGroup: FormGroup): void {
    const peakPowerControl = electricityFormGroup.get('peakPower');
    if (this.region === Region.flanders && electricityFormGroup.get('smartMeter')?.value) {
      this.addValidator(peakPowerControl, [Validators.required]);
    } else {
      this.removeValidator(peakPowerControl, [Validators.required]);
    }
  }

  private updateValidatorsBasedOnSolarPanels(electricityFormGroup: FormGroup): void {
    const solarPanelsKvaControl = electricityFormGroup.get('solarPanelsKva');
    if (electricityFormGroup.get('hasSolarPanels').value) {
      this.addValidator(solarPanelsKvaControl, this.solarPanelKvaValidators);
    } else {
      this.removeValidator(solarPanelsKvaControl, this.solarPanelKvaValidators);
    }
  }

  private updateValidatorsForWallonia(electricityFormGroup: FormGroup): void {
    const solarPanelsInstallationDateControl = electricityFormGroup.get('solarPanelsInstallationDate');
    if (
      this.region === Region.wallonia &&
      electricityFormGroup.get('smartMeter').value &&
      electricityFormGroup.get('hasSolarPanels').value
    ) {
      this.addValidator(solarPanelsInstallationDateControl, [Validators.required]);
    } else {
      this.removeValidator(solarPanelsInstallationDateControl, [Validators.required]);
    }
  }

  private addValidator(control, validators: ValidationErrors[]): void {
    control.addValidators(validators);
    control.updateValueAndValidity({ emitEvent: false });
  }

  private removeValidator(control, validators: ValidationErrors[]): void {
    control.removeValidators(validators);
    control.updateValueAndValidity({ emitEvent: false });
  }

  private emitFormValidity(): void {
    const electricityGroup = this.metersFormGroup.get('electricity');
    this.formValidityEmitter.emit(electricityGroup.valid);
    electricityGroup.valueChanges.subscribe(() => this.formValidityEmitter.emit(electricityGroup.valid));
  }

  private getIndexesValidators(): Validators[] {
    return moment(this.moveInDate).isSameOrBefore(moment()) ? [Validators.required] : [];
  }

  onHasSolarPanelChanges(event: Event) {
    const { checked }: { checked: boolean } = <HTMLInputElement>event.target;
    const electricityGroup = this.metersFormGroup.get('electricity') as FormGroup;
    electricityGroup.get('hasSolarPanels').setValue(checked);
  }
}

_('errorMessages.requiredField');
_('errorMessages.solarPanelsRange');
