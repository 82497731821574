import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationComponent } from '@app/modules/customer-zone/move/components/move-form/navigation/navigation.component';
import { LoaderStatus } from '@app/modules/customer-zone/move/models/status.interface';
import { catchError, filter, forkJoin, Observable, of, switchMap, take, timer } from 'rxjs';
import { MoveFormFacade } from '@app/core/facade/move-form.facade';
import { INITIAL_MOVE_STATE, MoveDTO, MoveState } from '@app/core/state/move.state';
import { MoveInRegistration } from '@app/modules/customer-zone/move/models/movein.interface';
import { UploadDocumentParamsDreCompletionStatusEnumCuzoApi } from '@app/shared/models/cuzo-be-contract';
import { MainFacade } from '@app/core/facade/main.facade';
import { SummaryMovingInfoComponent } from '@app/modules/customer-zone/move/components/move-form/steps/finalise/summary-moving-info/summary-moving-info.component';
import { SummaryMyMetersComponent } from '@app/modules/customer-zone/move/components/move-form/steps/finalise/summary-my-meters/summary-my-meters.component';
import { MoveFormPaths } from '@app/core/models/paths';
import { SummaryNewAddressComponent } from '@app/modules/customer-zone/move/components/move-form/steps/finalise/summary-new-address/summary-new-address.component';
import { SummaryNewMetersComponent } from '@app/modules/customer-zone/move/components/move-form/steps/finalise/summary-new-meters/summary-new-meters.component';
import { MoveFormStep } from '@app/modules/customer-zone/move/components/move-form/steps/MoveFormStep';
import moment from 'moment';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-finalise',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    NavigationComponent,
    SummaryMovingInfoComponent,
    SummaryMyMetersComponent,
    SummaryNewAddressComponent,
    SummaryNewMetersComponent,
  ],
  templateUrl: './finalise.component.html',
  styleUrls: ['./finalise.component.scss'],
})
export class FinaliseComponent extends MoveFormStep<Boolean> implements OnInit {
  protected readonly UploadDocumentParamsDreCompletionStatusEnumCuzoApi =
    UploadDocumentParamsDreCompletionStatusEnumCuzoApi;
  moveDTO: MoveDTO;
  registration: MoveInRegistration;
  readonly: boolean;

  constructor(public moveFormFacade: MoveFormFacade, public facade: MainFacade) {
    super(moveFormFacade);
  }

  ngOnInit(): void {
    this.moveFormFacade.state$
      .pipe(
        filter((): boolean => this.moveFormFacade.state$.value !== INITIAL_MOVE_STATE),
        take(1)
      )
      .subscribe((state: MoveState) => {
        this.moveDTO = state?.moveDTO;
        this.registration = state?.registration;
        this.readonly = state?.readonly;
      });
  }

  saveFormData(): Observable<Boolean> {
    return of(true);
  }

  onNextClickedDefault(): void {
    const registration = this.moveFormFacade.state$.value.registration;
    const moveOut = this.moveFormFacade.state$.value.moveDTO;
    registration.signatureDate = moveOut.signatureDate = moment().toISOString();
    this.moveFormFacade.loader$.next(LoaderStatus.LOADING);
    forkJoin([this.moveFormFacade.update(moveOut), this.moveFormFacade.updateRegistration(registration)])
      .pipe(switchMap(() => this.moveFormFacade.submitMove()))
      .subscribe({
        next: () => {
          this.moveFormFacade.redirectToMoveDashboard();
          this.moveFormFacade.loader$.next(LoaderStatus.LOADED);
          window.localStorage.setItem('moveOutSigned', moveOut?.id);
        },
        error: () => {
          this.moveFormFacade.loader$.next(LoaderStatus.LOADED);
        },
      });
  }

  onPreviousClickedDefault(): void {
    this.moveFormFacade.loader$.next(LoaderStatus.LOADING);
    timer(500)
      .pipe(take(1))
      .subscribe((): void => {
        this.moveFormFacade.previous();
        this.moveFormFacade.loader$.next(LoaderStatus.LOADED);
      });
  }

  edit(stepUrl: MoveFormPaths): void {
    const route = this.moveFormFacade.state$.value.route;
    const stepIndex = route.findIndex((r) => r === stepUrl);
    this.moveFormFacade.updateData({
      currentStepUrl: stepUrl,
      currentStep: stepIndex,
    });
  }
}
