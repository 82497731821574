import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { NavigationComponent } from '../../navigation/navigation.component';
import { INITIAL_MOVE_STATE, MoveFormFrontend } from '@app/core/state/move.state';
import { MoveFormStep } from '../MoveFormStep';
import { filter, Observable, take } from 'rxjs';
import { LoaderStatus } from '@app/modules/customer-zone/move/models/status.interface';
import { MainFacade } from '@app/core/facade/main.facade';
import { MoveFormFacade } from '@app/core/facade/move-form.facade';
import { TranslateModule } from '@ngx-translate/core';
import { MoveInRegistration } from '@app/modules/customer-zone/move/models/movein.interface';
import { ShowHide } from '@app/shared/utils/animations';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

// TODO Connect to API interface
export interface NewOccupant {
  hasNewOccupant: boolean;
  contractType: string;
  firstName: string;
  lastName: string;
  phone: string;
}

type FormGroupControls<T> = {
  [K in keyof T]: FormControl<T[K]>;
};

type NewOccupantForm = FormGroupControls<NewOccupant>;

@Component({
  selector: 'app-new-occupant',
  standalone: true,
  imports: [CommonModule, NavigationComponent, ReactiveFormsModule, TranslateModule],
  templateUrl: './new-occupant.component.html',
  styleUrls: ['./new-occupant.component.scss'],
  animations: [ShowHide],
})
export class NewOccupantComponent extends MoveFormStep<MoveInRegistration> implements OnInit {
  formGroup: FormGroup<NewOccupantForm> = new FormGroup({
    hasNewOccupant: new FormControl(null, Validators.required),
    contractType: new FormControl(null, Validators.required),
    firstName: new FormControl(''),
    lastName: new FormControl(''),
    phone: new FormControl(''),
  });
  registration: MoveInRegistration;

  constructor(private readonly facade: MainFacade, protected readonly moveFormFacade: MoveFormFacade) {
    super(moveFormFacade);
    this.formGroup
      .get('hasNewOccupant')
      .valueChanges.pipe(takeUntilDestroyed())
      .subscribe((value: boolean) => {
        const contractType = this.formGroup.get('contractType');
        value ? contractType.enable() : contractType.disable();
      });
  }

  ngOnInit(): void {
    this.moveFormFacade.state$
      .pipe(
        filter((): boolean => this.moveFormFacade.state$.value !== INITIAL_MOVE_STATE),
        take(1)
      )
      .subscribe((state) => {
        this.registration = state?.registration;
        // TODO For now from local storage
        this.formGroup.patchValue(state?.form?.newOccupant);
      });
  }

  onPreviousClickedDefault() {
    this.moveFormFacade.loader$.next(LoaderStatus.LOADING);
    this.moveFormFacade.updateData({ form: this.getForm() });
    this.moveFormFacade.previous();
    this.moveFormFacade.loader$.next(LoaderStatus.LOADED);
  }

  onNextClickedDefault() {
    if (this.formGroup.valid) {
      this.moveFormFacade.updateData({ form: this.getForm() });
      this.moveFormFacade.loader$.next(LoaderStatus.LOADING);
      this.saveFormData().subscribe(() => {
        this.moveFormFacade.loader$.next(LoaderStatus.LOADED);
        this.moveFormFacade.next();
      });
    }
  }

  saveFormData(): Observable<MoveInRegistration> {
    this.registration = this.mapFormToMoveIn();
    return this.moveFormFacade.updateRegistration(this.registration).pipe(take(1));
  }

  private getForm(): MoveFormFrontend {
    return {
      ...this.moveFormFacade?.state$?.value?.form,
      newOccupant: {
        ...this.formGroup.value,
      },
    };
  }
  private mapFormToMoveIn(): MoveInRegistration {
    let payload = structuredClone(this.registration);
    payload = {
      ...payload,
      newOccupant: {
        ...this.formGroup.value,
      },
    };
    return payload;
  }
}
